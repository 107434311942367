import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import ScrollTopButton from "./ScrollTopButton";

import axios from "axios";

const tokenLinkBase =
  "https://opensea.io/assets/matic/0xB22F939f97fC269BCd12a9528217fC6cc20716b7/";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  rootFilter: {
    margin: "10vw 2vw",
  },
  container: {
    marginTop: "10em!important",
  },
    popup: {
    position: 'absolute',
    backgroundColor: '#000000',
    padding: '10px 25px',
    border: '1px solid #f8a100',
    zIndex: 1000,
    top: '40%',
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: 'inset 0 0  1em #f8a100, 0 0  1em #f8a100',
    color: '#f8a100!important',  fontSize: '3rem', fontFamily:'UprightBoldNarrow'
},

'totalMints.PG[data-tooltip]': {
  '&::before':{
  zIndex: '99999999!important'}
},
}));

function Info({
  handleReplace,
  fetchData,
  updates,
  loading,
  baseUrl,
  lan,
  isVolumeOn,
}) {
  const classes = useStyles();
  const [filteredUpdates, setFilteredUpdates] = useState([]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const getContractAddress = async () => {
    const res = await axios.get(`${baseUrl}/api/v1/data/contractAddress`);
    if (res && res.data && res.data.contractAddress) {
      setTokenLinkBase(
        `https://opensea.io/assets/matic/${res.data.contractAddress}/`,
      );
    }
  };

  const [tokenLinkBase, setTokenLinkBase] = useState(
    "https://opensea.io/assets/matic/0xB22F939f97fC269BCd12a9528217fC6cc20716b7/",
  );

  useEffect(() => {
    window.document.title =
      lan && lan === "PG" ? "IGUN AI" : "IGUN AI — by — Minne";
    fetchData();
    handleReplace();
    getContractAddress();
  }, []);

  useEffect(() => {
    if (filteredUpdates && filteredUpdates.length === 0) {
      const filteredData = shuffleArray(
        updates.filter((upd) => upd.objectName.toLowerCase().includes("head")),
      );
      setFilteredUpdates(filteredData);
    }
  }, [updates]);
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}></div>
      ) : (
        <div className="mobile-igun-header" style={{ margin: "18vw 2vw 2vw" }}>
          <a
            href="https://link.springer.com/article/10.1007/s00146-023-01761-7"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <div className="tickerContainer">
                              <div 
    onMouseEnter={() => setShowPopup(true)}
    onMouseLeave={() => setShowPopup(false)}
    style={{ position: 'relative' }}

>  
              <div className="tickerText" style={{ lineHeight:'1', marginBottom:'10px'}}>
                {/* Content duplicated multiple times */}
                {Array(3)
                  .fill()
                  .map((_, idx) => (
                    <>
                      <span
                        className="headCounts"
                        data-tooltip="DYNAMIC ENTRY"
                      ></span>{" "}
                      of{" "}
                      <span
                        className="totalMints PG"
                        data-tooltip="DYNAMIC ENTRY"
                      ></span>{" "}
                      documented artifacts in my training data.{" "}
                    </>
                  ))}
              </div>
            </div></div>
          </a>
            {showPopup && (
        <div className={classes.popup}>
            READ THE PAPER
        </div>
    )}

          <Grid container spacing={2}>
            {filteredUpdates.map((upd, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <div
                  className="gallery-item"
                  onClick={() =>
                    window.open(
                      `${tokenLinkBase}${upd.tokenNum.slice(2)}`,
                      "_blank",
                    )
                  }
                >
                  <h6 className="objectid" style={{ display: "inline-block" }}>
                    {upd.Id}
                  </h6>
                  <h3>
                    <span className="museumtitle">INSTITUTION </span>
                    <span className="museumcontent">{upd.museum}</span>
                  </h3>
                  <h3>
                    <span className="objecttitle"> OBJECT </span>
                    <span className="objectcontent">{upd.objectName}</span>
                  </h3>
                  <h3>
                    <span className="returntitle">RETURN DATE</span>
                    <span className="returncontent">{upd.returned}</span>
                  </h3>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <ScrollTopButton lan={lan} isVolumeOn={isVolumeOn} />
    </>
  );
}

export default Info;

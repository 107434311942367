import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import ReactGA from "react-ga";
import jsPDF from "jspdf";
import { fontBase64 } from "./fontBase64";


const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  termsItems: {
    listStyleType: "upper-roman",
  },
  rootFilter: {
    margin: "1vw 2vw",
  },
}));

function Popup({ setHasAccess, lan, fontStyles, handleReplace }) {
  const [isAgreed, setAgreed] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hoveredButton, setHoveredButton] = useState("");
  const [isPaused, setIsPaused] = useState("");
  let history = useHistory();
  const [currentTime, setCurrentTime] = useState("");
  const [hasOverlap, setHasOverlap] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showWelcomeAccess, setShowWelcomeAccess] = useState(true);
  const [showMuteButton, setShowMuteButton] = useState(false);
  const [showPdfButton, setShowPdfButton] = useState(false);
  const classes = useStyles();
  const [agreeButtonShownTime, setAgreeButtonShownTime] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [countdownTime, setCountdownTime] = useState(15);

  useEffect(() => {
    handleReplace();
  }, [
    isAgreed,
    showTerms,
    currentTime,
    showNotification,
    currentTime,
    lan,
    countdownTime,
    isPaused,
    handleReplace,
    agreeButtonShownTime,
    showWelcomeAccess,
    hoveredButton,
    hasOverlap,
    showAnimation,
    showAll,
    showMuteButton,
    showPdfButton,
  ]);

  const checkTermsAccepted = () => {
    const acc = localStorage.getItem("access");
    if (!acc) {
      // setShowWelcomeAccess(false);
    }
  };

  useEffect(() => {
    checkTermsAccepted();
  }, []);

  const closeNotification = () => {
    setShowNotification(false);
    setCountdownTime(15); // Reset the countdown time
  };



  useEffect(() => {
    if (countdownTime > 0 && showNotification) {
      const timerId = setTimeout(() => {
        setCountdownTime(countdownTime - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else if (countdownTime === 0) {
      closeNotification();
    }
  }, [countdownTime, showNotification]);

  const listContainerRef = useRef(null);

  const generatePdf = () => {
    const doc = new jsPDF(); // Define the 'doc' constant

    // Add custom font to VFS
    const fontName = "pdfLightNarrow.ttf";
    doc.addFileToVFS(fontName, fontBase64);

    // Register the custom font
    const customFontFamily = "pdfLightNarrow";
    doc.addFont(fontName, customFontFamily, "normal");

    // Set custom font as the active font
    doc.setFont(customFontFamily);

    const title = "TERMS OF ACCESS - BENINBRONZES.XYZ";
    const subtitle = "AN ARCHIVAL PROJECT BY MINNE ATAIRU";

    const now = new Date();
    const date = now.toLocaleDateString();
    const time = now.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
    });

    const dateTime =
      "DOWNLOADED ON " +
      date.replace(/\//g, ".") +
      " " +
      time.replace(/:/g, ".");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Obtain the list items text content
    const listItems = Array.from(
      listContainerRef.current.parentElement.querySelectorAll(".terms-item")
    ).map((item) => ({ text: item.textContent.trim() }));

    const addHeaderFooter = () => {
      // Add vertical date and time at the top right corner
      doc.setFontSize(15); // Adjust font size for the date and time

      // Add horizontal text at the bottom right corner
      const bottomRightText =
        "BENINBRONZES.XYZ   BENINBRONZES.XYZ   BENINBRONZES.XYZ   BENINBRONZES.XYZ   BENINBRONZES.XYZ   BENINBRONZES.XYZ   BENINBRONZES.XYZ   BENINBRONZES.XYZ";
      const textSize = doc.getTextWidth(bottomRightText);
      doc.text(bottomRightText, pageWidth - 0, pageHeight - 5, { angle: 90 });

      // Add page number at the bottom
      const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      doc.text(`${pageNumber}`, pageWidth / 2, pageHeight - 10); // 10 is offset from bottom

  // Check if this is the last page
  if (pageNumber === doc.internal.getNumberOfPages()) {
    // If this is the last page, add the download date
    const downloadDateText = `${dateTime} FROM BENINBRONZES.XYZ   ${dateTime} FROM BENINBRONZES.XYZ`;
    doc.setFontSize(15); // Set the font size for the download date
    doc.text(downloadDateText, 0, pageHeight - 0); // Position the download date
  }
  
      // Reset font size for the list items
      doc.setFontSize(55); // I used the updated font size from your previous question
    };

    // Set black background
    doc.setFillColor(0, 0, 0);
    doc.rect(0, 0, pageWidth, pageHeight, "F");

    // Set the font size for the title (for example, 2em or 32 points)
    doc.setFontSize(38);
    // Set the text color to white
    doc.setTextColor(224, 223, 211);

    doc.text(title, 10, 22); // Position the title
    doc.text(subtitle, 10, 34); // note that the y-coordinate is larger
    const dateTimeFontSize = 38;
    doc.setFontSize(dateTimeFontSize);
    doc.text(dateTime, 10, 46); // note that the y-coordinate is larger

  doc.setFontSize(55); 
  let paragraphText;

if (lan && lan === "PG") {
  paragraphText = "Abeg, make you read di condition wey dey guide how to enta inside dis archive.";
} else {
  paragraphText = "Below are the terms of access for the Metadata Memoir - an archive dedicated to documenting and maintaining records of reclaimed Benin Bronzes, starting from the year 2021 onwards.";

}

// Split the paragraph text into lines of specified length
const maxLineWidth = pageWidth - 10; // Subtract the left and right margin
const splitParagraphText = doc.splitTextToSize(paragraphText, maxLineWidth);

// Add paragraph underneath the date
const paragraphX = 10; // Left padding
const paragraphY = 100; // Adjust the y-coordinate as per your need
const paragraphWidth = pageWidth - (2 * paragraphX); // Subtract the left and right padding
doc.text(splitParagraphText, paragraphX, paragraphY, { maxWidth: paragraphWidth });

    doc.setFontSize(50); // change this to the desired value

    let yPos = 30; // Initialize Y position after title
    const rightPadding = 20; // Define your desired padding here
    const maxTextWidth = pageWidth - 20 - rightPadding; // Subtract the right padding from the maximum text width

    addHeaderFooter();

    doc.addPage(); // Add a new page
    doc.setFillColor(0, 0, 0);
    doc.rect(0, 0, pageWidth, pageHeight, "F"); // Set black background for the new page
    yPos = 10; // Reset Y position for the new page
    addHeaderFooter();
let nextPageAdded = false; // Keep track if a new page is added

for (const item of listItems) {
  const splitText = doc.splitTextToSize(item.text, maxTextWidth);
  const lineHeight = doc.getFontSize() * 1; // Increase line height for better readability

  yPos += lineHeight; // Move Y position to the next line

  doc.text(splitText, 10, yPos); // Add the wrapped text

  yPos += lineHeight * splitText.length; // Update Y position based on the number of lines

  // Check if the next item will fit on the current page
  if (yPos + lineHeight * 3 > pageHeight) {
    doc.addPage(); // Add a new page
    doc.setFillColor(0, 0, 0);
    doc.rect(0, 0, pageWidth, pageHeight, "F"); // Set black background for the new page
    yPos = 5; // Reset Y position for the new page
    addHeaderFooter();
    nextPageAdded = true;
  }
}

if (!nextPageAdded) {
  // Check if the last page is empty and remove it
  const pageCount = doc.internal.getNumberOfPages();
  if (pageCount > 1) {
    doc.deletePage(pageCount);
  }
}

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = pdfUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  };

  const handleContinue = () => {
    setShowTerms(true);
    setShowAll(true);
    setShowAnimation(true);
    setShowMuteButton(true);
    setShowPdfButton(true);
    setAgreeButtonShownTime(new Date());

    setShowAnimation(true);
  };

  const handleAgree = () => {
    const now = new Date();
    const elapsedTime = (now - agreeButtonShownTime) / 1000;

    if (elapsedTime < 15) {
      setShowNotification(true);
    } else {
      setAgreed(true);
      setHasAccess(true);
      history.push("/home");
      setIsPaused("agree");

      // Store the current timestamp when the terms are accepted
      // localStorage.setItem(
      //   "termsAcceptedTimestamp",
      //   new Date().getTime().toString()
      // );
      localStorage.setItem("access", true);
    }
  };

  const handleDisagree = () => {
    history.push("/no_access");
    setIsPaused("disagree");
  };

  const handleMouseOver = (button) => {
    setHoveredButton(button);
    if (button === "agree" && hoveredButton === "disagree") {
      setHasOverlap(true);
    } else if (button === "disagree" && hoveredButton === "agree") {
      setHasOverlap(true);
    } else {
      setHasOverlap(false);
    }
  };

  const handleMouseLeave = () => {
    setHoveredButton("");
    setHasOverlap(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDate = new Date();
      const options = {
        timeZone: "Africa/Lagos",
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      };
      const formattedTime = currentDate.toLocaleString("en-US", options);
      setCurrentTime(formattedTime);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    showWelcomeAccess && (
      <div className="welcomeaccess">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div >
            {showPdfButton && (
              <button
                className="download-terms"
                style={{
                  background: "transparent",
                  border: "2px solid #E0DFD3",
                  color: "#E0DFD3",
                  float: "left",
                  fontSize: "3em",
                }}
                onMouseOver={() => {
                }}
                onClick={generatePdf}
              >
                {lan && lan === "PG"
                  ? "DOWNLOAD DIS INFORMATE"
                  : "DOWNLOAD TERMS"}
              </button>
            )}
          </div>

          {showTerms && (
            <>
              {" "}
              {lan && lan === "PG" ? (
                <p
                  className="replace timeinbenin"
                  style={{ textAlign: "right", fontSize: "2rem", fontFamily:"symbols" }}
                >
                  Today na <span className="replace">{currentTime}</span> for
                  Benin city
                </p>
              ) : (
                <p
                  className="replace timeinbenin "
                  style={{ textAlign: "right", fontSize: "3em",  fontFamily:"symbols" }}
                >
                  It<span className="replace">'s {currentTime}</span> in Benin
                  city
                </p>
              )}
            </>
          )}
        </div>
        {showNotification && (
          <div
            style={{
              position: "fixed",
              top: "1rem",
              left: "1rem",
              color: "#e0dfd3",
              padding: "0.2rem 1rem",
              zIndex: 1000,
              fontSize: "2rem",
              fontFamily: "UprightBoldNarrow",
            }}
            className="have-you-read replace"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="replace"
            >
              {" "}
              <button
                onClick={closeNotification}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "#e0dfd3",
                  cursor: "pointer",
                  fontFamily: "lightNarrow",
                  fontSize: "2rem",
                  paddingRight: "20PX",
                }}
              >
                X
              </button>
              <span classname="replace have-you-read " style={{ margin: 0,  fontFamily:"symbols",color: "#e0dfd3" }}>
                {lan && lan === "PG"
                  ? "YOU DON READ THE INFORMATE? TIME WEY REMAIN - "
                  : "HAVE YOU READ THE TERMS? TIME LEFT - "}
                {countdownTime}s
              </span>
            </div>
          </div>
        )}

        {showTerms && (
          <div>
            <div>
              {" "}
              <ul className="terms-mm">
                <li
                  className="terms-item replace"
                  ref={listContainerRef}
                  style={{
                    opacity: 1,
                    transition: "opacity 1s ease-out",
                    fontSize: "3em",
                    lineHeight: 1,
                  }}
                >
                  {lan && lan === "PG" ? (
                    <span>
                      You gree say Bini Bronze get spiritual meaning for Bini
                      pipo<span className="replace">?</span>
                    </span>
                  ) : (
                    <span>
                      You acknowledge that the Benin Bronzes are sacred objects
                      to the Benin people.
                    </span>
                  )}
                </li>

                <li
                  className="terms-item replace"
                  ref={listContainerRef}
                  style={{
                    opacity: 1,
                    transition: "opacity 1s ease-out",
                    fontSize: "3em",
                    lineHeight: 1,
                  }}
                >
                  {lan && lan === "PG" ? (
                    <span className="replace">
                      You gree say na by force dem British soja carry Bini
                      Bronzes commot from Bini Kingdom for 1897, and e no good
                      as dem dey keep am for Oyinbo museums
                      <span className="replace">?</span>
                    </span>
                  ) : (
                    <span>
                      You acknowledge that the Benin Bronzes were violently
                      stolen from Benin Kingdom by British imperial forces in
                      1897 and are wrongfully held in Western museums,
                      galleries, private collections, and other venues across
                      the world.
                    </span>
                  )}
                </li>

                <li
                  className="terms-item replace"
                  ref={listContainerRef}
                  style={{
                    opacity: 1,
                    transition: "opacity 1s ease-out",
                    fontSize: "3em",
                    lineHeight: 1,
                  }}
                >
                  {lan && lan === "PG" ? (
                    <span>
                      You gree say na Bini pipo own Bini Bronzes, e suppose dey
                      dia hand <span className="replace">?</span>
                    </span>
                  ) : (
                    <span>
                      You acknowledge that the Benin Bronzes rightfully belong
                      to the Benin people.
                    </span>
                  )}
                </li>

                <li
                  className="terms-item replace"
                  ref={listContainerRef}
                  style={{
                    opacity: 1,
                    transition: "opacity 1s ease-out",
                    fontSize: "3em",
                    lineHeight: 1,
                  }}
                >
                  {lan && lan === "PG" ? (
                    <span>
                      You gree say di only way wey we go take settle dis
                      kolonial tif-tif mata na to make sure say Oyinbo pipo
                      return wetin dem tif from Bini pipo
                      <span className="replace">?</span>
                    </span>
                  ) : (
                    <span>
                      You acknowledge that return is the only acceptable
                      resolution to colonial theft.
                    </span>
                  )}
                </li>

                <li
                  className="terms-item replace"
                  ref={listContainerRef}
                  style={{
                    opacity: 1,
                    transition: "opacity 1s ease-out",
                    fontSize: "3em",
                    lineHeight: 1,
                  }}
                >
                  {lan && lan === "PG" ? (
                    <span>
                      You gree say na your luck wey give you di chance to see
                      dis website and di informate wey e get inside am - even
                      sef we no sure say some Benin pipo get dis informate
                      <span className="replace">?</span>
                    </span>
                  ) : (
                    <span className="replace">
                      You recognize that your privilege provides you access to
                      this website and the knowledge housed in it — one that may
                      be unavailable to those from whom the Bronzes have been
                      stolen.
                    </span>
                  )}
                </li>

                <li
                  className="terms-item"
                  ref={listContainerRef}
                  style={{
                    opacity: 1,
                    transition: "opacity 1s ease-out",
                    fontSize: "3em",
                    lineHeight: 1,
                  }}
                >
                  {lan && lan === "PG" ? (
                    <span>
                      You understand say dis website no dey sell Bini Bronze.
                      Wetin we dey do na to share beta informate about Bronzes
                      wey dey return to Naija
                      <span className="replace">?</span>
                    </span>
                  ) : (
                    <span>
                      You understand that this archive does not establish any
                      ownership rights over the Benin Bronzes and is strictly
                      for the purpose of providing return-related data.
                    </span>
                  )}
                </li>
              </ul>
            </div>

            <div
              className="introbuttons"
              style={{ textAlign: "center", margin: "10px", width: "80%" }}
            >
              <button
                className={`firstaccessbutt seesaw-button ${
                  hoveredButton === "agree" ? "overlap" : ""
                }`}
                style={{
                  fontSize: "calc(2rem + 7vw)",
                  margin: `${
                    hoveredButton === "agree" && hasOverlap
                      ? "-20px 10px 30px 10px"
                      : "10px"
                  }`,
                  zIndex: 1,
                }}
                onMouseOver={() => handleMouseOver("agree")}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  handleAgree();
                  ReactGA.event({
                    category: "Button Click",
                    action: "Clicked Agree Button",
                  });
                }}
              >
                {lan && lan === "PG" ? "I GREE" : "AGREE"}
              </button>

              <button
                className={`firstaccessbutt seesaw-button ${
                  hoveredButton === "disagree" || hoveredButton === "agree"
                    ? "overlap"
                    : ""
                }`}
                style={{
                  fontSize: "calc(2rem + 7vw)",
                  margin: `${
                    (hoveredButton === "disagree" ||
                      hoveredButton === "agree") &&
                    hasOverlap
                      ? "-20px 10px 30px 10px"
                      : "10px"
                  }`,
                  zIndex: 0,
                }}
                onMouseOver={() => handleMouseOver("disagree")}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  handleDisagree();
                  ReactGA.event({
                    category: "Button Click",
                    action: "Clicked Disagree Button",
                  });
                }}
              >
                {lan && lan === "PG" ? "I NO GREE" : "DISAGREE"}
              </button>
            </div>
          </div>
        )}

        {!showTerms && (
          <div className="firstaccess access-container">
            <p
              span
              className="access-text replace"
              style={{ fontSize: "calc(2rem + 7vw) " }}
            >
              {lan && lan === "PG"
                ? "To see dis informate, you gats gree to our terms of use."
                : " To access this archive, you'll need to accept our terms of use."}
            </p>
          </div>
        )}

        {!showTerms && (
          <button
            className="firstaccessbutt seesaw-button"
            style={{ fontSize: "calc(2rem + 7vw)" }}
            onClick={() => {
              handleContinue(); // Call your existing click handler function
              ReactGA.event({
                category: "Button Click",
                action: "Clicked Continue Button",
              });
            }}
          >
            {lan && lan === "PG" ? "MAKE I SEE" : "CONTINUE"}
          </button>
        )}
      </div>
    )
  );
}

export default Popup;

const convertToCSV = (objArray) => {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  // Arr => comma separated string
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    // Inserts at beginnning
    items.unshift(headers);
  }
  // Convert Object to JSON
  let jsonObject = JSON.stringify(items);

  let csv = convertToCSV(jsonObject);

  let exportedFilenmae = fileTitle + ".csv" || "export.csv";

  let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    // Saves file/blob to disk
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const fileTitle = "List of Reclaimed Benin Bronzes";

const headers = {
  //   re_Id: "Id",
  re_tokenNum: "Token Number",
  re_institution: "Institution",
  re_object: "Object",
  re_material: "Material",
  re_return: "Return Date (UTC)",
  re_produced: "Production Date (UTC)",
  re_recorded: "Recorded Date (UTC)",
};

export const downloadCSV = (arr) => {
  let itemsFormatted = [];

  // format the data
  arr.forEach((item) => {
    itemsFormatted.push({
      //   re_Id: item.Id.replace(/,/g, ""),
      re_tokenNum: item.tokenNum.replace(/,/g, ""),
      re_institution: item.museum.replace(/,/g, ""),
      re_object: item.objectName.replace(/,/g, ""),
      re_material: item.material.replace(/,/g, ""),
      re_return: item.returned.replace(/,/g, ""),
      re_produced: item.produced.replace(/,/g, ""),
      re_recorded: item.recorded.replace(/,/g, ""),
    });
  });

  exportCSVFile(headers, itemsFormatted, fileTitle);
};

import React, { useEffect, useState } from "react";

import { Paper, Typography, makeStyles } from "@material-ui/core";
import ScrollTopButton from "./ScrollTopButton";
import ReactGA from "react-ga";
import { Link } from "react-scroll";
import FilterSound from "../sounds/menu.mp3";
import SupSound from "../sounds/backtotop.mp3";
import CloseSound from "../sounds/closed.mp3";
import AllMaterialsPlot from "./AllMaterialsPlot";
import axios from "axios";
import AreaChart from "./AreaChart";
import IframeGridComponent from "./IframeGrid";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TheftYears from "./TheftYears";
import BiniStyler from "./BiniStyler";
import moment from "moment";

const audioClose = new Audio(CloseSound);

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
  },
}));

function About({
  handleReplace,
  fetchData,
  lan,
  myData,
  updates,
  materials,
  isVolumeOn,
  baseUrl,
  institutions,
  rDates,
  date,
}) {
  const [tokenLinkBase, setTokenLinkBase] = useState(
    "https://testnets.opensea.io/assets/bsc-testnet/0xB22F939f97fC269BCd12a9528217fC6cc20716b7/",
  );

  const getContractAddress = async () => {
    const res = await axios.get(`${baseUrl}/api/v1/data/contractAddress`);
    if (res && res.data && res.data.contractAddress) {
      setTokenLinkBase(
        `https://testnets.opensea.io/assets/bsc-testnet/${res.data.contractAddress}/`,
      );
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [clickedInstitution, setClickedInstitution] = useState(null);
  const togglePopup = (institution, count) => {
    setShowPopup(!showPopup);
    setClickedInstitution(institution);
    setPieChartData([{ name: institution, value: count }]);
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [showOverlay2, setShowOverlay2] = useState(false);
  const [showOverlay3, setShowOverlay3] = useState(false);
  const [showOverlay4, setShowOverlay4] = useState(false);
  const [showOverlay5, setShowOverlay5] = useState(false);
  const [showOverlay6, setShowOverlay6] = useState(false);
  const [showOverlay7, setShowOverlay7] = useState(false);
  const [showOverlay8, setShowOverlay8] = useState(false);
  const [showOverlay9, setShowOverlay9] = useState(false);
  const [pieChartData, setPieChartData] = useState([]);
  const [headItems, setHeadItems] = useState([]);

  const data = institutions.map(([institution, count]) => ({
    name: institution,
    value: count,
  }));
  const total = institutions.reduce((acc, [, count]) => acc + count, 0);

  // Assume you have a state or ref to keep the dimensions of the container
  const [chartDimensions, setChartDimensions] = React.useState({
    width: 750,
    height: 750,
  });

  // Function to get dimensions of the container, call it on mount or resize
  const updateDimensions = () => {
    const width = document.querySelector(".popup-content").clientWidth;
    const height = document.querySelector(".popup-content").clientHeight;
    setChartDimensions({ width, height });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0)",
            border: "none",
            fontFamily: "BoldRitalicNarrow",
            textTransform: "uppercase",
            textShadow: "2px 20px 50px #cf3031",
            fontSize: "calc(0.49914rem + 4.2vw)",
          }}
        >
          <p>{payload[0].name}</p>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    total,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentage = ((value / total) * 100).toFixed(2);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontFamily="Symbols"
        fontSize="1.5rem"
      >
        {`${percentage}%`}
      </text>
    );
  };

  const NORMAL_COLOR = "#000000";

  const [audio, setAudio] = useState(null);
  const [isMuted, setIsMuted] = useState(false);

  const audioFilter = new Audio(FilterSound);
  const audioBook = new Audio(SupSound);

  useEffect(() => {
    window.document.title =
      lan && lan === "PG" ? "Wetin Be Dis?" : "Research Notes — by — Minne";
    fetchData();
    handleReplace();
  }, []);

  const handleToggleIframe = () => {
    setShowOverlay(!showOverlay);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const handleToggleIframe2 = () => {
    setShowOverlay2(!showOverlay2);
  };

  const handleCloseOverlay2 = () => {
    setShowOverlay2(false);
  };

  const handleToggleIframe3 = () => {
    setShowOverlay3(!showOverlay3);
  };

  const handleCloseOverlay3 = () => {
    setShowOverlay3(false);
  };

  const handleToggleIframe4 = () => {
    setShowOverlay4(!showOverlay4);
  };

  const handleCloseOverlay4 = () => {
    setShowOverlay4(false);
  };

  const handleToggleIframe5 = () => {
    setShowOverlay5(!showOverlay5);
  };

  const handleCloseOverlay5 = () => {
    setShowOverlay5(false);
  };

  const handleToggleIframe6 = () => {
    setShowOverlay6(!showOverlay6);
  };

  const handleCloseOverlay6 = () => {
    setShowOverlay6(false);
  };

  const handleToggleIframe7 = () => {
    setShowOverlay7(!showOverlay7);
  };

  const handleCloseOverlay7 = () => {
    setShowOverlay7(false);
  };

  const handleToggleIframe8 = () => {
    setShowOverlay8(!showOverlay8);
  };

  const handleCloseOverlay8 = () => {
    setShowOverlay8(false);
  };

  const handleToggleIframe9 = () => {
    setShowOverlay9(!showOverlay9);
  };

  const handleCloseOverlay9 = () => {
    setShowOverlay9(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const subscriptionForm = document.querySelector(".subscription-form");

      if (subscriptionForm) {
        if (window.pageYOffset > 50) {
          subscriptionForm.style.display = "block";
        } else {
          subscriptionForm.style.display = "none";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Paper
        className="headspace"
        style={{ padding: "1vw", margin: "0 3vw", marginTop: "20%" }}
      >
        <div
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          {lan && lan === "PG" ? (
            <BiniStyler>
              <Typography
                color="textSecondary"
                className="about replace PG"
                id="about_primary"
              >
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0",
                  }}
                  handleReplace={handleReplace}
                  className="mobilewords"
                >
                  <span className="boldwide">
                    WETIN BE DIS<span className="replace">?</span>
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                  handleReplace={handleReplace}
                  className="mobilewords"
                >
                  Di <span className="boldwide">Metadata Memoir</span> na
                  decentralized archive wey dey gather informate about di Bini
                  Bronzes wey dem don return back to Bini City. If you no sabi
                  wetin Bini Bronze be—dem be ogbonge show of di kain artistic
                  sense wey Bini Artists dem get during di 13th reach 19th
                  century. As e be, we no sabi di name of di Artists wey make di
                  Bronzes, but we sabi say na Oba of Bini pay dem to create Bini
                  Bronzes with different different kain materials. Those olden
                  days artist dem finish work for di Bronzes. Dem design di Bini
                  Bronzes to carry spiritual meaning, wey pass ordinary eye.
                  Some Bronzes dem design for Oba of Bini to use am invoke e
                  ancestors or tok with di Gods{" "}
                  <span className="boldwords">
                    <span className="replace">[</span>
                    <span
                      className="beninbronze__sainsbury"
                      style={{
                        marginTop: "20px",
                      }}
                      variant="contained"
                      onClick={() => {
                        setIsMuted(!isMuted); // switch between mute and unmute
                        audioFilter.volume = isMuted ? 0 : 1; // mute if isMuted is true, unmute otherwise
                        if (isVolumeOn) {
                          audioFilter.play();
                        }
                        handleToggleIframe(); // Call your existing click handler function
                        ReactGA.event({
                          category: "Button Click",
                          action: showOverlay
                            ? "Hide am"
                            : "Click hia to see wan Benin Bronze in 3D",
                        });
                      }}
                    >
                      {showOverlay
                        ? "Hide am"
                        : "Click hia to see Oba Head for 3D"}
                    </span>
                    <span className="replace">]</span>
                  </span>
                  . Oda ones dem design to represent motor wey Oba of Bini fit
                  use travel go anoda world{" "}
                  <span className="boldwords">
                    <span className="replace">[</span>See one example of wetin
                    oyibo museum dey call{" "}
                    <a
                      className="zigzag-border"
                      href="https://www.britishmuseum.org/collection/object/E_Af1898-0115-31"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Mudfish
                    </a>{" "}
                    or as my pipo dey call am - Catfish
                    <span className="replace">]</span>
                  </span>
                  . Or e go fit look like foto wey Oba of Bini go use remember e
                  ancestors{" "}
                  <span className="boldwords">
                    <span className="replace">[</span>See example of wan wey
                    Oyibo museum don knack name as{" "}
                    <a
                      className="zigzag-border"
                      href="https://www.metmuseum.org/art/collection/search/310282"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Commemorative Head
                    </a>
                    <span className="replace">]</span>
                  </span>
                  . Even sef, e get some Bini Bronze wey you go fit read as
                  stories about Bini history{" "}
                  <span className="replace boldwords">[</span>
                  <span
                    className="beninbronze__sainsbury"
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe2();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay2
                          ? "hide am"
                          : "see wan example in 3D",
                      });
                    }}
                  >
                    {showOverlay2
                      ? "hide am"
                      : "Click hia to see wan 3D example wey Oyibo museum dey call Plaque"}
                    <span className="replace">]</span>
                  </span>
                  , and plenty other tins join join.
                </div>
                <AllMaterialsPlot
                  myData={myData}
                  updates={updates}
                  materials={materials}
                  isPer={true}
                  handleReplace={handleReplace}
                  lan={lan}
                />
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                  className="mobilewords"
                >
                  But, kasala burst for dia history. E happen for 1897, wen di
                  British empire bring dia kolonial soja con cause wahala for
                  Benin Kingdom sake of say dem wan control di plenty natural
                  resources wey dey Bini. Dem especially wan collect our palm
                  oil by force by fire, carry go sell for Europe where dey go
                  make plenty profit. Why dem dey craze for our palm oil so
                  <span style={{ fontFamily: "symbols" }}>?</span> For dat time,
                  Oyibo pipo con see say machine fit helep human being do
                  handwork shaperly. Instead of to dey use hand dey make tins
                  one by one, dem con build plenty factory with machines wey fit
                  do di handwork <span className="replace">[</span>Dat time na
                  wetin historians dey call Industrial Revolution
                  <span className="replace">]</span>. Na so Oyibo pipo con see
                  say, for machine to do work sharp-sharp, dey go need plenty
                  palm oil to oil di engine of di machine wey go make di factory
                  product, and dey go also use palm oil as ingedient inside
                  factory products like
                  <span style={{ fontFamily: "symbols" }}>:</span> soap, candle,
                  margarine, body cream. As British pipo dey tink say{" "}
                  <span style={{ fontFamily: "symbols" }}>
                    "God don buta dia bread"
                  </span>
                  , Oba of Benin say e no gree O
                  <span style={{ fontFamily: "symbols" }}>!</span> Oba say make
                  Oyibo no con trade anyhow for inside Bini, make dem dey pay
                  tax, and say di market no go dey open every day. Dis one con
                  make British pipo vex wella-wella. If you wan sabi more about
                  di 1897 invasion wahala, you fit watch di Nollywood film wey
                  Lancelot Oduwa Imasuen make, e name am{" "}
                  <a
                    className="zigzag-border boldwide"
                    href="https://www.imdb.com/title/tt5260860/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Invasion 1897
                  </a>
                  . E go tell you wella about all di kasala wey shele dat time.
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                  className="mobilewords"
                >
                  Na so British Empire send dia soja to scatter everything for
                  Benin. Dem burn Oba Ovonramwen palace, carry Oba Ovonramwen by
                  force go Calabar, and tif pass{" "}
                  <span className="boldwide">4,000</span> Bini Bronzes wey dey
                  inside Oba Ovonramwen palace, shrines and oda places. Di sojas
                  con carry di tif tif property go England, con sell to Oyinbo
                  museum pipo as tins wey dem bring from di journey wey cause
                  suffer suffer for we pipo. As e dey now, more than{" "}
                  <span className="boldwide">160</span> museums all ova di world
                  don buy Bini Bronze, but di majority of di Oyibo museum wey
                  get dis tif-tif property dey for Europe and North America. Dis
                  museums dem get different different kain of Benin Bronze
                  <span className="replace">:</span>{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=979"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    spoon
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=991"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    key
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=1092"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    cup
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=299"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    pin
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=1092"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    pot
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=1086"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    handfan
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=1081"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ring
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=263"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    stool
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=971"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bracelet
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=173"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    necklace
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=213"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    chair
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=1269"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bag
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=1268"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    wrappa
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=494"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    even one bead
                    <span style={{ fontFamily: "symbols" }}>!</span>
                  </a>{" "}
                  Even tins wey don{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.cp3c.org/benin-bronzes/object.php?o=11"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    break break
                  </a>
                  , spoil finish, dem tif.
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                  handleReplace={handleReplace}
                  className="appendix mobilewords"
                >
                  Clik di boxes hia to see Bini Bronzes for 3D. Abeg, make sure
                  say your cellular data dey plenty, because dis wan go chop am
                  wella.
                </div>{" "}
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                  handleReplace={handleReplace}
                  className="boldwords mobilewords"
                >
                  <IframeGridComponent lan={lan} />
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0",
                  }}
                  handleReplace={handleReplace}
                  className="mobilewords boldwide"
                >
                  HOW E CON CHANGE DI COST OF GARRI
                  <span className="replace">?</span>
                </div>
                <div
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                  handleReplace={handleReplace}
                  className="replace mobilewords"
                >
                  Make I tell you about di money wey Oyinbo pipo don make from
                  Bini Bronzes. For 1897, di cost of Bini Bronze go from{" "}
                  <span class="guinea-wrapper">
                    <span className="figurative-bronze">13 to 14 guineas</span>,{" "}
                    <span class="guinea-popup">
                      EXCHANGE RATE FOR OCTOBER 2023
                      <br />
                      <br />
                      1 guinea = NGN 981.24
                      <br />
                      13 guineas x N981.24 = N12,850.764
                      <br />
                      14 guineas x N981.24 = N13,736.36
                    </span>
                  </span>
                  , and later, e rise reach{" "}
                  <span class="guinea-wrapper">
                    <span className="figurative-bronze">16 to 28 guineas</span>,{" "}
                    <span class="guinea-popup">
                    EXCHANGE RATE FOR OCTOBER 2023
                      <br />
                      <br />
                      1 guinea = NGN 981.24
                      <br />
                      16 guineas x N981.24 = N15,699.84
                      <br />
                      28 guineas x N981.24 = N19,624.8
                    </span>
                  </span>
                  . By 1900, dem value one ivory carving at{" "}
                  <span class="guinea-wrapper">
                    <span className="figurative-bronze">100 guineas</span>
                    <span class="guinea-popup">
                    EXCHANGE RATE FOR OCTOBER 2023
                      <br />
                      <br />
                      1 guinea = NGN 981.24
                      <br />
                      100 guineas X N981.24 = N98,124
                      <br />
                    </span>
                  </span>
                  . For 1924, pesin tok say e buy two bronze leopard for £3, but
                  dem con sell am again for 1952 to Naija govment for £7,133. Di
                  next year, 1953, British Museum sell ten bronze plaques to
                  Naija Govment for £1,500. If we do di calculation, e go be
                  £150 per plaque. As time pass, the price of Bini Bronze dey go
                  higher like price of garri. On June 16, 1980, Sotheby's hold
                  auction wey dem call "Works of Art from Benin" and the opening
                  cost na: £484,000 [Lot 8], £484,000 [Lot 15], £352,000 [Lot
                  22]. On December 31, 1969, Christie's collect $662,500 for
                  wetin dem call "extremely fine Benin Bronze head," and for
                  1980, another bronze head sell for £1,200,000.
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                  handleReplace={handleReplace}
                  className="replace mobilewords"
                >
                  As of{" "}
                  <span
                    className="boldwords most-recent-date"
                    data-tooltip="DYNAMIC ENTRY"
                  >
                    {date && moment(date, "MM.DD.YYYY").format("MMMM DD, YYYY")}
                  </span>
                  , na only{" "}
                  <span
                    className="totalMints boldwords PG "
                    data-tooltip="DYNAMIC ENTRY"
                  ></span>{" "}
                  Bini Bronze wey Oyibo museums don return back Naija{" "}
                  <span className="replace">—</span> dis na only shikini of di{" "}
                  <span className="boldwide">4,000</span> wey British soja tif.
                  Even though Oyinbo museum tok say dem wan bring di Bronzes
                  back, e still be like say di kain talk wey dey happen between
                  di Naija govment and di museums wey get stolen Bronzes get
                  k-leg. Today dey go tok one tin, tomorrow dey go tok another
                  tin.
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                  handleReplace={handleReplace}
                  className="mobilewords replace"
                >
                  <span className="replace">
                    {" "}
                    One serious matter wey we suppose watch well-well be say
                    Naija govment no get website wey dey show all di Bini
                    Bronzes wey don come back. Wetin dey burst my head pass be
                    say, when Oyibo museums return Bini Bronze to Naija, dem dey
                    somehow delete di online informate. Make I give you example:
                    on November 10, 2022 Germany return{" "}
                    <span className="boldwide">21</span> Bini Bronze, but dem no
                    share informate about <span className="boldwide">14</span>{" "}
                    of dem. Dem no show us di picture of di Bronzes. Dem no
                    share dia names. We neva take korokoro eye see these{" "}
                    <span className="boldwide">14</span> Bini Bronze. As Naija
                    govment dey try bring di Bini Bronzes back, e go good make
                    we dey ask important kweshuns: How we fit take make sure say
                    di return agreement dey open, and say Naija govment dem dey
                    do am wella-wella?
                  </span>
                </div>
                <div
                  className="museummain-container"
                  style={{ marginTop: "5rem", marginBottom: "4rem" }}
                >
                  {showPopup ? (
                    <div className="popup">
                      <div className="popup-content">
                        <span className="close-inst" onClick={togglePopup}>
                          CLOSE
                        </span>
                        {/* Pie Chart */}
                        <div className="full-width-height">
                          <PieChart
                            className="recharts-wrapper"
                            width={chartDimensions.width}
                            height={chartDimensions.height}
                          >
                            {/* SVG definitions for radial gradient */}
                            <defs>
                              <radialGradient
                                id="RadialGradient"
                                cx="50%"
                                cy="50%"
                                r="50%"
                                fx="50%"
                                fy="50%"
                              >
                                <stop
                                  offset="0%"
                                  style={{
                                    stopColor: "#cf3031",
                                    stopOpacity: 1,
                                  }}
                                />
                                <stop
                                  offset="100%"
                                  style={{
                                    stopColor: "#000000",
                                    stopOpacity: 1,
                                  }}
                                />
                              </radialGradient>
                            </defs>
                            <Pie
                              dataKey="value"
                              isAnimationActive={true}
                              animationDuration={800}
                              animationEasing="ease-out"
                              data={data}
                              cx={chartDimensions.width / 2}
                              cy={chartDimensions.height / 2}
                              outerRadius={
                                Math.min(
                                  chartDimensions.width,
                                  chartDimensions.height,
                                ) /
                                  2 -
                                10
                              }
                              innerRadius={160} // this line changes it to a donut chart
                              fill="#8884d8"
                              stroke="#ffffff"
                              strokeDasharray="4 4"
                              label={(props) =>
                                renderCustomizedLabel({ ...props, total })
                              }
                            >
                              {data.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    entry.name === clickedInstitution
                                      ? "url(#RadialGradient)"
                                      : NORMAL_COLOR
                                  }
                                />
                              ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                          </PieChart>
                        </div>

                        <p className="pie-caption">
                          Objects reclaimed from {clickedInstitution}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  <ul className="museum-container">
                    {institutions.map(([institution, count]) => (
                      <li
                        key={institution}
                        onClick={() => togglePopup(institution)}
                      >
                        {institution}

                        {institution === "Germany" && (
                          <p
                            className="replace"
                            style={{
                              fontSize: "calc(0.13301rem + 1.5vw)",
                              fontFamily: "lightnarrow",
                              lineHeight: "1",
                            }}
                          >
                            Ethnologisches Museum of the Staatliche Museen zu
                            Berlin
                          </p>
                        )}

                        {institution === "Smithsonian" && (
                          <p
                            className="replace"
                            style={{
                              fontSize: "calc(0.13301rem + 1.5vw)",
                              fontFamily: "lightnarrow",
                              lineHeight: "1",
                            }}
                          >
                            National Museum for African Art
                          </p>
                        )}

                        <br />
                        <span className="countClass">
                          {count} {count === 1 ? "OBJECT" : "OBJECTS"}
                        </span>
                        <br />

                        {rDates && rDates[institution.toLowerCase()] && (
                          <span className="returnClass">
                            LAST RETURN {rDates[institution.toLowerCase()]}
                          </span>
                        )}
                        <br />
                      </li>
                    ))}
                  </ul>

                  <p
                    className="chart--caption"
                    style={{ textAlign: "left", margin: "0" }}
                  >
                    List wey dey show di museums wey don return Benin Bronzes
                    from 2021 reach now.{" "}
                  </p>
                  <p
                    className="chart--caption"
                    style={{ textAlign: "left", margin: "0" }}
                  >
                    Last time wey e update na{" "}
                    <span className="lastUpdate replace"></span>
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0",
                  }}
                  handleReplace={handleReplace}
                  className="mobilewords boldwide"
                >
                  WETIN WE FIT DO<span className="replace">?</span>
                </div>
                <div
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                  }}
                  handleReplace={handleReplace}
                  className="replace mobilewords"
                >
                  E possible to get technology wey fit helep us see everi everi
                  of how di return dey take happen
                  <span className="replace">?</span> Make I share with una wan
                  idea. I dey call am di
                  <span className="boldwide">Metadata Memoir</span>
                  <span className="replace">-</span>e be{" "}
                  <span className="boldwords"> system</span> wey I wan use to
                  create unchangeable record of Bini Bronze wey Oyibo museum don
                  return back to Naija. Dis idea no dey try to give complete
                  answer to all di kweshuns wey I don ask before, but na to try
                  understand wetin we fit do to helep make di return process
                  more transparent. How e go take work
                  <span className="replace">?</span> I go build am with wetin
                  computa pipo dey call{" "}
                  <span className="boldwords"> decentralized protocol</span>.
                  Dis mean say di system no dey controlled by one pesin, but di
                  control dey spread across many different different computas.
                  Dis kain system good wella, because e go hard make only one
                  pesin or one group get control over wetin I go record about
                  Bini Bronze or wetin I no go record about di Bronze. Di most
                  important tin be say dis recordings dey automatic, e dey
                  permanent, and e dey public. No pesin or govment fit change
                  am. E stand gbagam
                  <span className="replace" style={{ fontFamily: "symbols" }}>
                    !
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                  handleReplace={handleReplace}
                  className="replace mobilewords"
                >
                  So, di{" "}
                  <span className="boldwords"> decentralized protocol</span> dey
                  use wetin computa pipo dey call{" "}
                  <span className="boldwords"> smart contract</span>. Everyday,
                  di{" "}
                  <a
                    className="zigzag-border"
                    href="https://polygonscan.com/address/0x5fb11bdb718f8d7d32540a6cc781d40f0ef43eba#code"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="boldwords">smart contract</span>
                  </a>{" "}
                  go automatically search online for news wey dey mention return
                  Bini Bronzes. As soon as di smart contract find news, e go con
                  record information about each bronze on wetin I dey call{" "}
                  <a
                    className="zigzag-border"
                    href="https://opensea.io/assets/matic/0x5fb11BDb718f8d7d32540A6cC781D40F0Ef43ebA/50"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="boldwords">on-chain record</span>
                  </a>
                  . Di kain information wey e dey record include
                  <span className="replace">:</span> di date wen dem return the
                  Bronze, di museum wey responsible for di return, and e go also
                  create tiri informate about di{" "}
                  <span className="boldwords">object name</span>,{" "}
                  <span className="boldwords">
                    material wey dem use make di Bronze
                  </span>
                  , and correct{" "}
                  <span className="boldwords">olden days date</span> wey Bini
                  Artists make di Bronze. Dis tiri informate go con update
                  later. Di reason bi say, sometimes, news pipo dem no dey
                  publish di tiri informate because dem sef, dey no know am or e
                  go be say di museum no release am. So, I need to go find where
                  dis correct information dey, confam am, before I con add am to
                  di record. Before I go put di correct informate, you go see
                  space for di tiri informate with words
                  <span className="replace">: </span>
                  <span className="boldwords">Benin Bronze</span>,{" "}
                  <span className="boldwords">Bronze</span>, and{" "}
                  <span className="boldwords">Pre-1897</span>.
                </div>
                <AreaChart
                  myData={myData}
                  updates={updates}
                  materials={materials}
                  handleReplace={handleReplace}
                  lan={lan}
                />
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                  handleReplace={handleReplace}
                  className="replace"
                >
                  <span className="replace mobilewords">
                    Make I remind una say di{" "}
                    <span className="boldwide">Metadata Memoir</span> na project
                    wey{" "}
                    <a
                      className="zigzag-border boldwords"
                      href="https://minneatairu.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Usominne
                    </a>{" "}
                    dey do, and e no get any connection with any museum, Naija
                    govment or Oba of Bini. Dis na to make sure say dis project
                    dey independent of any arrangee wey fit pour sand sand for
                    my garri. Di project main purpose na to record di Bini
                    Bronzes wey don return back to Naija. Di project no go fit
                    helep find Bini Bronze wey pesin tif, or wey pesin dey hide
                    for inside kpata. I no design dis project for Oyibo museum
                    to con say,{" "}
                    <span className="replace">
                      dem no go return Bini Bronze because dis project na anoda
                      way to do return. I no dey kollect or sell Bini Bronze for
                      hia. For my eye, to return Bini Bronze mean say Oyibo
                      museum take di object wey dem tif, carry am go back Naija,
                      go Bini. Na wetin return mean be dat
                      <span
                        className="replace"
                        style={{ fontFamily: "symbols" }}
                      >
                        !
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  className="mobilewords zigzag-border"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  If you get question, abeg email am go
                  <span className="replace">:</span>{" "}
                  <a
                    className="zigzag-border"
                    href="mailto:return@beninbronzes.xyz"
                  >
                    return<span className="replace">@</span>beninbronzes.xyz
                  </a>
                </div>
                <ol className="chart-info-parent">
                  {" "}
                  <Typography>
                    <h2 id="datatori" className="chart-info-title replace">
                      DATA TORI
                    </h2>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", fontFamily: "symbols" }}
                    >
                      Di German Govment don accept say na dem go handle di mata
                      of returning Bini Bronze comot from all German museums
                      back to Naija. So, all di on-chain record wey relate to
                      German museum dey use di name - Germany, instead of di
                      museum name kpa kpa. Dis name dey important for di smart
                      contract because after I check news wey dey talk about di
                      Bini Bronze and German museums, I con see say news pipo no
                      dey use German museum name. Dem dey use the countri name -
                      Germany.
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{
                        lineHeight: "1",
                        fontFamily: "symbols",
                        marginTop: "10px",
                      }}
                    >
                      All di measurements dem dey base on di data wey dey enta
                      inside di smart contract. For example, to calculate di
                      percentage of Brass material, di smart contract go use di
                      total number of Bini Bronze wey dem don carry go Naija and
                      the total number of Brass material, con do di mathematics,
                      find the correct answer.
                    </li>
                  </Typography>
                  <Typography>
                    <li className="chart-info  replace">
                      Any live update dey take at least 1 minute before e go
                      show for di archive.
                    </li>
                  </Typography>
                  <Typography>
                    <li className="chart-info  replace">
                      All di classes of materials dem dey base on information
                      wey dey available to di public.
                    </li>
                  </Typography>
                  <Typography>
                    <li className="chart-info replace">
                      If we put di word{" "}
                      <span className="boldwords">Undisclosed</span>, e mean say
                      di museum wey dey return di Bini Bronze neva share di
                      informate.
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1" }}
                    >
                      {" "}
                      If you wan know if any informate wey you dey read na
                      automatic, abeg, hover ova di text, you go see small
                      message wey go say{" "}
                      <span className="boldwords">Dynamic Entry</span>.
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1.5" }}
                    >
                      All di 3D models wey you see na files wey different
                      museums don release to di public.
                    </li>
                  </Typography>
                </ol>
              </Typography>
            </BiniStyler>
          ) : (
            <BiniStyler>
              <Typography
                color="textSecondary"
                className="about replace"
                id="about_primary"
              >
                <div
                  className="mobilewords replace"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span id="igun-ai" style={{ fontFamily: "boldWide" }}>
                    ABSTRACT{" "}
                  </span>
                </div>
                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  The <span className="boldwide">Metadata Memoir</span>
                  <sup>
                    <Link
                      to="ref1"
                      smooth={true}
                      duration={500}
                      id="note1"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      1
                    </Link>
                  </sup> is an Archive-in-Waiting.{" "}
                  It is designed to document 
                  reclaimed
                  <sup>
                    <Link
                      to="ref2"
                      smooth={true}
                      duration={500}
                      id="note2"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      2
                    </Link>
                  </sup>{" "}
                  Benin Bronzes<span className="replace">—</span>a collection of secular and sacred artifacts looted from Benin Kingdom in
                  1897. <TheftYears />, conventional archives do little more
                  than catalog the colonial violence that legitimized the 1897
                  British invasion. A colonial intelligence report coldly reads:
                  "sufficient ivory may be found in the King's house" to finance
                  <sup>
                    <Link
                      to="ref3"
                      smooth={true}
                      duration={500}
                      id="note3"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      3
                    </Link>
                  </sup>{" "}
                  the invasion of Benin—"the city of blood"
                  <sup>
                    <Link
                      to="ref4"
                      smooth={true}
                      duration={500}
                      id="note4"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      4
                    </Link>
                  </sup>
                  . In 1898, a post-invasion report
                  <sup>
                    <Link
                      to="ref5"
                      smooth={true}
                      duration={500}
                      id="note5"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      5
                    </Link>
                  </sup>{" "}
                  notes, "they [Benin Bronzes] are a new Codex Africanus, not
                  written on fragile papyrus, but in ivory and imperishable
                  brass". A curator's ledger entitled "Fate of the Benin
                  Bronzes" itemizes their dispersal to various
                  institutions: the British Museum, Berlin, Pitt Rivers,
                  Dresden, Horniman
                  <sup>
                    <Link
                      to="ref6"
                      smooth={true}
                      duration={500}
                      id="note6"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      6
                    </Link>
                  </sup>
                  . Reports detail how the “official booty of the expedition”
                  was auctioned to “defray the cost of pensions” for the
                  colonial forces
                  <sup>
                    <Link
                      to="ref7"
                      smooth={true}
                      duration={500}
                      id="note7"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      7
                    </Link>
                  </sup>
                  . For example, in 1901, 562
                  <sup>
                    <Link
                      to="ref8"
                      smooth={true}
                      duration={500}
                      id="note8"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      8
                    </Link>
                  </sup>{" "}
                  Benin Bronzes were listed for sale in W. D. Webster's
                  "Illustrated Catalogue of Ethnographical Specimens, in Bronze,
                  Wrought Iron, Ivory and Wood from Benin City, West Africa..."
                  A year later, in 1902, J.C. <span id="stevens">Stevens'</span>{" "}
                  auction catalog titled "Bronze specimens"
                  <sup>
                    <Link
                      to="ref9"
                      smooth={true}
                      duration={500}
                      id="note9"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      9
                    </Link>
                  </sup>{" "}
                  featured the following cryptic listings: Ditto. Ditto. Ditto.
                  Ditto with four legs. Ditto. Bronze bell with incised and
                  raised ornaments. Ditto x 23.
                </div>
                <ul>
                  {headItems.map((item, index, objectName, headitems) => (
                    <li key={index}>{item.objectName}</li>
                  ))}
                </ul>
                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  <span className="replace">
                    Consider the economics of the 1897 plunder: In that year,
                    prices for Benin Bronzes rose from
                  </span>{" "}
                  <span class="guinea-wrapper">
                    <span className="figurative-bronze">13 to 14 guineas</span>,{" "}
                    <span class="guinea-popup">
                      CONVERSION DETAILS
                      <br />
                      <br />
                      1 guinea = £1.05
                      <br />
                      13 guineas x £1.05 = £13.65
                      <br />
                      14 guineas x £1.05 = £14.70
                    </span>
                  </span>
                  surging later to{" "}
                  <span class="guinea-wrapper">
                    <span className="figurative-bronze">16 to 28 guineas</span>
                    <span class="guinea-popup">
                      CONVERSION DETAILS
                      <br />
                      <br />
                      1 guinea = £1.05
                      <br />
                      16 guineas x £1.05 = £16.80
                      <br />
                      28 guineas x £1.05 = £29.40
                    </span>
                  </span>
                  . In 1900, an ivory carving was
                  valued at{" "}
                  <span class="guinea-wrapper">
                    <span className="figurative-bronze">100 guineas</span>
                    <span class="guinea-popup">
                      CONVERSION DETAILS
                      <br />
                      <br />
                      1 guinea = £1.05
                      <br />
                      100 guineas X £1.05=£105
                      <br />
                    </span>
                  </span>
                  <sup>
                    <Link
                      to="ref10"
                      smooth={true}
                      duration={500}
                      id="note10"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      10
                    </Link>
                  </sup>
                  .{" "}
                  <span className="replace">
                    In J.C. Stevens' 1902 auction catalog, the items ambiguously
                    listed as "Ditto" & "Bronze bell with incised and raised
                    ornament" were auctioned for £2.5 each. "Ditto, with four
                    legs" and another "ditto" fetched a price of £4.5 each. In 1924, a
                    mere “three pounds bought two leopards", only to be resold
                    in 1952 to the Nigerian government at a staggering £7,133
                  </span>
                  <sup>
                    <Link
                      to="ref11"
                      smooth={true}
                      duration={500}
                      id="note11"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      11
                    </Link>
                  </sup>
                  .
                  <span className="replace">
                    {" "}
                    The following year, in 1953, the British Museum sold
                  </span>
                  <sup>
                    <Link
                      to="ref12"
                      smooth={true}
                      duration={500}
                      id="note12"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      12
                    </Link>
                  </sup>
                  <span className="replace">
                    {" "}
                    ten "duplicate" plaques to the Nigerian Government at what
                    was termed an "especially low price of £1,500", averaging
                    £150 per plaque. Prices soared in subsequent sales. On
                    June 16, 1980, Sotheby's held an auction titled "Works of
                    Art from Benin" with the following opening bids: £484,000
                    [Lot 8], £484,000 [Lot 15], £352,000 [Lot 22]
                  </span>
                  <sup>
                    {" "}
                    <Link
                      to="ref13"
                      smooth={true}
                      duration={500}
                      id="note13"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      13
                    </Link>
                  </sup>
                  . On December 31, 1969, Christie's secured<sup>
                   
                    <Link
                      to="ref14"
                      smooth={true}
                      duration={500}
                      id="note14"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      14
                    </Link>
                  </sup>{" "}
                  <span className="replace">
                    {" "}
                    $662,500 for an "extremely fine Benin Bronze head," and by
                    1980, another such head sold for an astounding £1,200,000.
                  </span>
                </div>

                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                 How, then, does
                  one, as Hartman [2019]
                  <sup>
                    <Link
                      to="ref15"
                      smooth={true}
                      duration={500}
                      id="note15"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      15
                    </Link>
                  </sup>{" "}
                  writes, "grapple with the power and the authority of the
                  archive, and the limits it sets on what can be known, and who
                  is endowed with gravity and authority as historical actor"?
                  <span className="replace">
                    {" "}
                    In what ways might a counter-archive be engineered to remain
                    "untethered and indifferent to the rules of the [art]
                    historical guild"
                  </span>
                  —the very custodians of the empire's plunder? Who is the intended audience for such an archive? "The assembly, the ensemble, the
                  multitude, the chorus?"
                  <sup>
                    <Link
                      to="ref16"
                      smooth={true}
                      duration={500}
                      id="note16"
                      onClick={() => {
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      16
                    </Link>
                  </sup>
                </div>
                <div
                  className="mobilewords replace"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span id="igun-ai" style={{ fontFamily: "boldWide" }}>
                    A BLUEPRINT FOR THE METADATA MEMOIR
                  </span>
                </div>

                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  The extraordinary{" "}
                  <span className="replace boldwords">Benin Bronzes</span> are
                  products of unnamed and unsung artistic geniuses
                  <sup>
                    <Link
                      to="ref17"
                      smooth={true}
                      duration={500}
                      id="note17"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      17
                    </Link>
                  </sup>{" "}
                  who flourished under the patronage of the Benin
                  <sup>
                    <Link
                      to="ref18"
                      smooth={true}
                      duration={500}
                      id="note18"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      18
                    </Link>
                  </sup>{" "}
                  royal court between the 13th and 19th centuries. Each Benin Bronze is
                  meticulously crafted{" "}
                  <span className="replace boldwords">[</span>
                  <span
                    className="beninbronze__sainsbury"
                    style={{
                      marginTop: "20px",
                    }}
                    variant="contained"
                    onClick={() => {
                      // const gvolume = setvolume();
                      // audioFilter.volume = gvolume ? 1 : 0;
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay
                          ? "Hide 3D artifact"
                          : "view head of an Oba in 3D",
                      });
                    }}
                  >
                    {showOverlay
                      ? "Hide 3D artifact"
                      : "view head of an Oba in 3D"}
                  </span>
                  <span className="replace">]</span> with iconography that
                  defines its purpose in either sacred or secular contexts. Some
                  examples include
                  <span className="replace">:</span>{" "}
                  <span className="boldwords">conduits</span>
                  <sup>
                    <Link
                      to="ref19"
                      smooth={true}
                      duration={500}
                      id="note19"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      19
                    </Link>
                  </sup>{" "}
                  to facilitate dialogue with the{" "}
                  <span className="replace">Oba's [King's]</span> ancestors and
                  divine entities, <span className="boldwords">vehicles</span>
                  <sup>
                    <Link
                      to="ref20"
                      smooth={true}
                      duration={500}
                      id="note20"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      20
                    </Link>
                  </sup>{" "}
                  for the <span className="replace">Oba's</span> interplanetary
                  travel, <span className="boldwords">portraits</span>
                  <sup>
                    <Link
                      to="ref21"
                      smooth={true}
                      duration={500}
                      id="note21"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      21
                    </Link>
                  </sup>{" "}
                  in honor of royal predecessors,{" "}
                  <span className="boldwords">visual records</span>
                  <sup>
                    <Link
                      to="ref22"
                      smooth={true}
                      duration={500}
                      id="note22"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      22
                    </Link>
                  </sup>{" "}
                  of significant events, and much more.
                </div>
                <AllMaterialsPlot
                  myData={myData}
                  updates={updates}
                  materials={materials}
                  isPer={true}
                  handleReplace={handleReplace}
                  lan={lan}
                />
                <div
                  className="mobilewords replace"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  {" "}
                  The Benin Bronzes not only showcase the unparalleled artistic
                  brilliance of their creators but also bear witness to a
                  colonial chapter in Benin<span className="replace">'</span>s
                  history. In 1897, the British Empire launched a military
                  invasion of Benin Kingdom aiming to establish
                  colonial control over the region and exploiting its abundant
                  natural resources, particularly the lucrative palm oil industry
                  <sup>
                    <Link
                      to="ref23"
                      smooth={true}
                      duration={500}
                      id="note23"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      23
                    </Link>
                  </sup>
                  . This invasion culminated in the pillage of approximately{" "}
                  <span className="boldwide">4,000</span> exquisitely carved
                  ivory objects <span className="replace boldwords">[</span>
                  <span
                    className="beninbronze__sainsbury"
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe3();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay3
                          ? "hide 3D artifact"
                          : "view ivory scepter in 3D",
                      });
                    }}
                  >
                    {showOverlay3
                      ? "hide 3D artifact"
                      : "view ivory scepter in 3D"}
                    <span className="replace">]</span>
                  </span>
                  , metal casts in-the-round
                  <sup>
                    <Link
                      to="ref24"
                      smooth={true}
                      duration={500}
                      id="note24"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      24
                    </Link>
                  </sup>{" "}
                  and relief <span className="replace boldwords">[</span>
                  <span
                    className="beninbronze__sainsbury"
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe2();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay2
                          ? "hide 3D artifact"
                          : "view relief brass fragment in 3D",
                      });
                    }}
                  >
                    {showOverlay2
                      ? "hide 3D artifact"
                      : "view relief brass fragment in 3D"}
                    <span className="replace">]</span>
                  </span>
                  , coral beaded jewelry
                  <sup>
                    <Link
                      to="ref25"
                      smooth={true}
                      duration={500}
                      id="note25"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      25
                    </Link>
                  </sup>
                  , wood carvings
                  <sup>
                    <Link
                      to="ref26"
                      smooth={true}
                      duration={500}
                      id="note26"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      26
                    </Link>
                  </sup>
                  , terracotta sculptures
                  <sup>
                    <Link
                      to="ref27"
                      smooth={true}
                      duration={500}
                      id="note27"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      27
                    </Link>
                  </sup>{" "}
                  and a range of artifacts from Oba Ovonramwen
                  <span className="replace">'</span>s
                  <sup>
                    <Link
                      to="ref28"
                      smooth={true}
                      duration={500}
                      id="note28"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      28
                    </Link>
                  </sup>{" "}
                  palaces, shrines and other communal spaces. Following this
                  mass pillage, the artifacts were exported to England and
                  auctioned off as trophies of a civilizing mission.
                  As a result, <span className="boldwide">160</span>
                  <sup>
                    <Link
                      to="ref29"
                      smooth={true}
                      duration={500}
                      id="note29"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      29
                    </Link>
                  </sup>{" "}
                  Western cultural institutions have become custodians of the
                  looted artifacts, collectively known as the
                    Benin Bronzes
                  <sup>
                    <Link
                      to="ref30"
                      smooth={true}
                      duration={500}
                      id="note30"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      30
                    </Link>
                  </sup>
                  <span className="replace">
                    . Some 3D-scanned examples include:
                  </span>{" "}
                  <span
                    className="beninbronze__sainsbury replace"
                    style={{
                      marginTop: "20px",
                      textDecoration: "underline dashed",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe4();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay4
                          ? "hide 3D artifact"
                          : "Ovonramwen's throne",
                      });
                    }}
                  >
                    {showOverlay4
                      ? "hide 3D artifact"
                      : "Oba Ovonramwen's throne"}
                  </span>
                  ,{" "}
                  <span
                    className="beninbronze__sainsbury replace"
                    style={{
                      marginTop: "20px",
                      textDecoration: "underline dashed",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe5();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay5
                          ? "hide 3D artifact"
                          : "seated human figure",
                      });
                    }}
                  >
                    {showOverlay5
                      ? "hide 3D artifact"
                      : "a seated human figure"}
                  </span>
                  ,{" "}
                  <span
                    className="beninbronze__sainsbury replace"
                    style={{
                      marginTop: "20px",
                      textDecoration: "underline dashed",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe6();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay6
                          ? "hide 3D artifact"
                          : "altar tusk",
                      });
                    }}
                  >
                    {showOverlay6 ? "hide 3D artifact" : "an altar tusk"}
                  </span>
                  ,{" "}
                  <span
                    className="beninbronze__sainsbury replace"
                    style={{
                      marginTop: "20px",
                      textDecoration: "underline dashed",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe7();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay7
                          ? "hide 3D artifact"
                          : "terracotta head",
                      });
                    }}
                  >
                    {showOverlay7 ? "hide 3D artifact" : "terracotta head"}
                  </span>
                  ,{" "}
                  <span
                    className="beninbronze__sainsbury replace"
                    style={{
                      marginTop: "20px",
                      textDecoration: "underline dashed",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe8();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay8
                          ? "hide 3D artifact"
                          : "Udo style head",
                      });
                    }}
                  >
                    {showOverlay8 ? "hide 3D artifact" : "Udo style head"}
                  </span>
                  ,{" "}
                  <span
                    className="beninbronze__sainsbury replace"
                    style={{
                      marginTop: "20px",
                      textDecoration: "underline dashed",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (isVolumeOn) {
                        audioFilter.play();
                      }
                      handleToggleIframe9();
                      ReactGA.event({
                        category: "Button Click",
                        action: showOverlay9
                          ? "hide 3D artifact"
                          : "leopard mask",
                      });
                    }}
                  >
                    {showOverlay9 ? "hide 3D artifact" : "leopard mask"}
                  </span>
                  .
                </div>

                <div
                  className="museummain-container"
                  style={{ marginTop: "5rem", marginBottom: "4rem" }}
                >
                  {showPopup ? (
                    <div className="popup">
                      <div className="popup-content">
                        <span className="close-inst" onClick={togglePopup}>
                          CLOSE
                        </span>
                        {/* Pie Chart */}
                        <div className="full-width-height">
                          <PieChart
                            className="recharts-wrapper"
                            width={chartDimensions.width}
                            height={chartDimensions.height}
                          >
                            {/* SVG definitions for radial gradient */}
                            <defs>
                              <radialGradient
                                id="RadialGradient"
                                cx="50%"
                                cy="50%"
                                r="50%"
                                fx="50%"
                                fy="50%"
                              >
                                <stop
                                  offset="0%"
                                  style={{
                                    stopColor: "#cf3031",
                                    stopOpacity: 1,
                                  }}
                                />
                                <stop
                                  offset="100%"
                                  style={{
                                    stopColor: "#000000",
                                    stopOpacity: 1,
                                  }}
                                />
                              </radialGradient>
                            </defs>
                            <Pie
                              dataKey="value"
                              isAnimationActive={true}
                              animationDuration={800}
                              animationEasing="ease-out"
                              data={data}
                              cx={chartDimensions.width / 2}
                              cy={chartDimensions.height / 2}
                              outerRadius={
                                Math.min(
                                  chartDimensions.width,
                                  chartDimensions.height,
                                ) /
                                  2 -
                                10
                              }
                              innerRadius={160} // this line changes it to a donut chart
                              fill="#8884d8"
                              stroke="#ffffff"
                              strokeDasharray="4 4"
                              label={(props) =>
                                renderCustomizedLabel({ ...props, total })
                              }
                            >
                              {data.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    entry.name === clickedInstitution
                                      ? "url(#RadialGradient)"
                                      : NORMAL_COLOR
                                  }
                                />
                              ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                          </PieChart>
                        </div>

                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "1.5rem",
                            fontFamily: "BoldRitalicNarrow",
                            textTransform: "uppercase",
                            fontSize: "calc(0.49914rem + 4.2vw)",
                            lineHeight: "0.8",
                          }}
                        >
                          Objects reclaimed from {clickedInstitution}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  <ul className="museum-container">
                    {institutions.map(([institution, count]) => (
                      <li
                        key={institution}
                        onClick={() => togglePopup(institution)}
                      >
                        {institution}

                        {institution === "Germany" && (
                          <p
                            className="replace"
                            style={{
                              fontSize: "calc(0.13301rem + 1.5vw)",
                              fontFamily: "lightnarrow",
                              lineHeight: "1",
                            }}
                          >
                            Ethnologisches Museum of the Staatliche Museen zu
                            Berlin
                          </p>
                        )}

                        {institution === "Smithsonian" && (
                          <p
                            className="replace"
                            style={{
                              fontSize: "calc(0.13301rem + 1.5vw)",
                              fontFamily: "lightnarrow",
                              lineHeight: "1",
                            }}
                          >
                            National Museum for African Art
                          </p>
                        )}

                        <br />
                        <span className="countClass">
                          {count} {count === 1 ? "OBJECT" : "OBJECTS"}
                        </span>
                        <br />

                        {rDates && rDates[institution.toLowerCase()] && (
                          <span className="returnClass">
                            LAST RETURN {rDates[institution.toLowerCase()]}
                          </span>
                        )}
                        <br />
                      </li>
                    ))}
                  </ul>

                  <p
                    className="chart--caption"
                    style={{ textAlign: "left", margin: "0" }}
                  >
                    Ongoing index of institutions that have returned Benin
                    Bronze from 2021 to present{" "}
                  </p>
                  <p
                    className="chart--caption"
                    style={{ textAlign: "left", margin: "0" }}
                  >
                    Data as of <span className="lastUpdate replace"></span>
                  </p>
                </div>

                <div
                  className="mobilewords"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  {" "}
                  As of the most recent documented return on{" "}
                  <span
                    className="boldwords most-recent-date"
                    data-tooltip="DYNAMIC ENTRY"
                  >
                    {date && moment(date, "MM.DD.YYYY").format("MMMM DD, YYYY")}
                  </span>
                  <sup>
                    <Link
                      to="ref31"
                      smooth={true}
                      duration={500}
                      id="note31"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      31
                    </Link>
                  </sup>
                  , efforts to reclaim the Benin Bronzes have resulted in the
                  repossession of approximately{" "}
                  <span
                    className="totalMints PG boldwide"
                    data-tooltip="DYNAMIC ENTRY"
                  ></span>
                  <sup>
                    <Link
                      to="ref32"
                      smooth={true}
                      duration={500}
                      id="note32"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      32
                    </Link>
                  </sup>{" "}
                  artifacts<span className="replace">—</span>a minuscule
                  fraction of the total <span className="boldwide">4000</span>+
                  plundered. The Nigerian government, now entrusted with the returned artifacts, has yet to publicly disclose details of each piece. A case in point is the
              list of <span className="boldwide">14</span> Benin
                  Bronzes returned by German authorities, which I have labeled "
                  <a
                    href="https://opensea.io/collection/metadatamemoir?search[stringTraits][0][name]=Material&search[stringTraits][0][values][0]=Undisclosed"
                    className="boldwide"
                    style={{ textDecoration: "underline dashed" }}
                  >
                    undisclosed
                  </a>
                  ". This documentation gap is further exacerbated when institutions delete online records of the returned artifacts including provenance
                  information, publications and exhibition histories. In light of these concerns, I ask: Where does one
                  retrieve information about the reclaimed and now-erased Benin
                  Bronzes? What methods might be deployed to quantitatively
                  assess reclamation efforts?
                </div>
                <AreaChart
                  myData={myData}
                  updates={updates}
                  materials={materials}
                  handleReplace={handleReplace}
                  lan={lan}
                />
                <div
                  className="mobilewords replace"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  To address these questions, I propose the{" "}
                  <span className="boldwide">Metadata Memoir</span>
                  <span className="replace">—</span>a decentralized archive
                  designed to document each Bronze
                  <span className="replace">'</span>s transition from colonial
                  possession to reclaimed artifact. Managed
                  by a{" "}
                  <a
                    className="zigzag-border"
                    href="https://polygonscan.com/address/0x5fb11bdb718f8d7d32540a6cc781d40f0ef43eba#code"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="boldwords">smart contract</span>
                  </a>, the{" "}
                  <span className="boldwide">Metadata Memoir</span> {" "}
                  executes a daily search
                  <sup>
                    <Link
                      to="ref33"
                      smooth={true}
                      duration={500}
                      id="note33"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      33
                    </Link>
                  </sup>{" "}
                  for press releases related to the return of Benin Bronzes.
                  Upon identifying a relevant press release, the smart contract
                  generates an{" "}
                  <a
                    className="zigzag-border"
                    href="https://opensea.io/assets/matic/0x5fb11BDb718f8d7d32540A6cC781D40F0Ef43ebA/50"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="boldwords">on-chain record</span>
                  </a>
                  <sup>
                    <Link
                      to="ref34"
                      smooth={true}
                      duration={500}
                      id="note34"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      34
                    </Link>
                  </sup>
                  . This record captures metadata
                  <sup>
                    <Link
                      to="ref35"
                      smooth={true}
                      duration={500}
                      id="note35"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      35
                    </Link>
                  </sup>{" "}
                  including the date of repatriation, the institution
                  responsible for repatriation, and dynamic
                  <sup>
                    <Link
                      to="ref36"
                      smooth={true}
                      duration={500}
                      id="note36"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      36
                    </Link>
                  </sup>{" "}
                  fields for <span className="boldwords">object name</span>,{" "}
                  <span className="boldwords">material composition</span>, and{" "}
                  <span className="boldwords">production date</span>, each
                  denoted by the respective default values
                  <span className="replace">:</span>{" "}
                  <span className="boldwords">Benin Bronze</span>,{" "}
                  <span className="boldwords">Bronze</span>, and
                  <span className="boldwords"> Pre-1897</span>. While the smart
                  contract automates the documentation process, human oversight
                  is needed to populate and validate the aforementioned dynamic
                  fields.
                  <sup>
                    <Link
                      to="ref37"
                      smooth={true}
                      duration={500}
                      id="note37"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      37
                    </Link>
                  </sup>
                </div>
                <div
                  className="mobilewords replace"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span id="igun-ai" style={{ fontFamily: "boldWide" }}>
                    THE PRECURSOR<span className="replace">:</span> LOOTED DATA
                    CURATION
                  </span>
                </div>

                <div
                  className="mobilewords replace"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  The <span className="boldwide">Metadata Memoir</span> began as
                  a simple{" "}
                  <a
                    href="https://beninbronzetracker.minneatairu.com/"
                    className="boldwords"
                    style={{ textDecoration: "underline dashed" }}
                  >
                    spreadsheet
                  </a>{" "}
                  with a sole objective: to identify which of the{" "}
                  <span className="boldwide">1,182</span> Benin Bronzes featured in my{" "}
                  <a
                    href="https://link.springer.com/article/10.1007/s00146-023-01761-7"
                    className="boldwords"
                    style={{ textDecoration: "underline dashed" }}
                  >
                    styleGAN
                  </a> training data had been <span className="boldwide">physically</span>{" "}
                  returned to Nigeria. However, as my research evolved, this basic
                  tool evolved into what I now describe as an{" "}
                  <span className="boldwords">Archive-in-Waiting</span>. I use this term to denote its decentralized architecture, 
                  purposefully designed to remain in a state of anticipation,
                  ever-ready to document the homecoming of the Benin Bronzes. As
                  indicated by the most recent documentation dated{" "}
                  <span
                    className="boldwords most-recent-date"
                    data-tooltip="DYNAMIC ENTRY"
                  >
                    {date && moment(date, "MM.DD.YYYY").format("MMMM DD, YYYY")}
                  </span>
                  , approximately{" "}
                  <span
                    className="boldwide headCounts"
                    data-tooltip="DYNAMIC ENTRY"
                  ></span>{" "}
                  of the{" "}
                  <span
                    className="totalMints PG boldwide"
                    data-tooltip="DYNAMIC ENTRY"
                  ></span>{" "}
                  reclaimed Benin Bronzes correlate with images in my{" "}
                  <a
                    href="https://beninbronzes.xyz/igun/"
                    className="boldwords"
                    style={{ textDecoration: "underline dashed" }}
                  >
                    dataset
                  </a>
                  . These {" "}
                  <span
                    className="boldwide headCounts"
                    data-tooltip="DYNAMIC ENTRY"
                  ></span>{" "} artifacts belong to the category of figurative objects curated for my training data. Find object details <a
                    href="https://beninbronzes.xyz/igun/"
                    className="boldwords"
                    style={{ textDecoration: "underline dashed" }}
                  >
                   here
                  </a>.
                </div>

                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  Though this archive offers quantitative insights about
                  reclaimed Benin Bronzes, it is deeply entwined with my
                  personal history and heritage. My ancestors hail from a humble
                  fishing settlement in{" "}
                  <span className="replace">
                    Etsako, a geopolitical region located approximately 100
                    miles north of Benin City. No familial ties link me to the
                    royal bloodlines.{" "}
                  </span>
                  None of my Aunts enjoyed the scandalous privilege of being a
                  mistress to an Oba, nor a disavowed Queen. As our oral history
                  recounts, in the early 1500s, my ancestors royal privileges
                  were revoked due to their refusal to pay an annual tax imposed
                  by the Oba<span className="replace">—</span>a leopard
                  <sup>
                    <Link
                      to="ref38"
                      smooth={true}
                      duration={500}
                      id="note38"
                      onClick={() => {
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      38
                    </Link>
                  </sup>
                  . This act of defiance bore grave repercussions
                  <span className="replace">
                    : my ancestors were exiled from the heart of Benin city to
                    a satellite town.
                  </span>{" "}
                </div>

                <div
                  className="mobilewords"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  {" "}
                  <span className="replace">
                    {" "}
                    Far removed from the grandeur of Benin's royal court, I haven't
                    inherited cultural wealth in the form of bronze, ivory, corals,
                    or praise poetry. Instead of such material assets, I've
                    amassed a profound appreciation for the seemingly pedestrian
                    yet extraordinary artistic and intellectual pursuits of
                    ordinary folk, both from Benin and beyond. Foremost among
                    these are words of wisdom by Benin oral historians, and
                    lamentations articulated by Nigerian poets who so eloquently
                    bemoan the desecration of our Gods — "deshrined", "stilted
                    on plastic", "traded in for an O.B.E", and exhibited for
                    "the carious laugh of philistine revelers" [Osundare, 1983]
                  </span>
                  <sup>
                    <Link
                      to="ref39"
                      smooth={true}
                      duration={500}
                      id="note39"
                      onClick={() => {
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      39
                    </Link>
                  </sup>
                  <span className="replace">
                    . Osundare's somber verse further stirs my soul, conjuring a
                    heart-wrenching dialogue: "I ask for Ogidigbonyingboyin mask
                    of Benin", followed by a disheartening reply, "The moon says
                    it is in London". My fascination extends to the realm of
                    theatre, where Yerima's [1998]
                  </span>
                  <sup>
                    <Link
                      to="ref40"
                      smooth={true}
                      duration={500}
                      id="note40"
                      onClick={() => {
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      40
                    </Link>
                  </sup>{" "}
                  play masterfully portrays the tribulations endured by the{" "}
                  <span className="replace">
                    {" "}
                    "Home-Leopard of the Benin Empire - O - v - o - n - r - a -
                    m - w - e - n
                  </span>{" "}
                  <span className="replace" style={{ paddingLeft: "30px" }}>
                    {" "}
                  </span>{" "}
                  <span className="replace">
                    N - o - g - b - a - i - s - i"
                  </span>
                  <span className="replace">
                    {" "}
                    — "a man more sinned against than he ever sinned"
                  </span>
                  <sup>
                    <Link
                      to="ref41"
                      smooth={true}
                      duration={500}
                      id="note41"
                      onClick={() => {
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      41
                    </Link>
                  </sup>
                  <span className="replace">
                    — who beseeches the Gods for a "different" second coming
                    [Rotimi, 1974]
                  </span>
                  <sup>
                    <Link
                      to="ref42"
                      smooth={true}
                      duration={500}
                      id="note42"
                      onClick={() => {
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      42
                    </Link>
                  </sup>
                  .{" "}
                  <span className="replace">
                    Oba Ovonramwen's trials continue to reverberate in somber
                    80s highlife lyrics by{" "}
                  </span>
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.youtube.com/watch?v=sRtNt5OKX6g"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Osayomore Joseph
                  </a>
                  , and{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.youtube.com/watch?v=dPkFhk6nbjc&pp=ygUUb2JhIG92b25yYW13ZW4gbXVzaWM%3D"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Osemwegie Ebohon
                  </a>{" "}
                  <span className="replace">
                    {" "}
                    — their music born amidst Nigeria's early struggle to
                    reclaim the Benin Bronzes.{" "}
                  </span>{" "}
                  Finally, I am ever inspired by contemporary Benin Bronze
                  casters
                  <span className="replace"> [See:</span>{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://www.pejulayiwola.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Peju Layiwola
                  </a>
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://republic.com.ng/gender/who-is-elizabeth-olowu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Elizabeth Olowu
                  </a>{" "}
                  ,{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://guardian.ng/art/unmasking-alufa-unsung-hero-of-festac-77/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Joseph Alufa Igbinovia
                  </a>
                  
                    ] who boldly defy their "ancestral obligations" [Enwezor & Okeke-Agulu, 2009]<sup>
                    <Link
                      to="ref43"
                      smooth={true}
                      duration={500}
                      id="note43"
                      onClick={() => {
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      43
                    </Link>
                  </sup>
                 {" "}choosing instead to forge artistic responses to Benin's 
                    cultural present.{" "}
                 
                </div>
                <div
                  className="mobilewords boldwords"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span style={{ fontFamily: "boldWide" }}>LIMITATIONS</span>
                </div>
                <div
                  className="mobilewords "
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                >
                  The <span className="boldwide">Metadata Memoir</span> is an ongoing 
                  independent project by{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://minneatairu.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Minne
                  </a>
                  . It is managed by a smart contract that autonomously collects
                  news data from{" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://developer.nytimes.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    The New York Times
                  </a>
                  , and {" "}
                  <a
                    className="zigzag-border boldwords"
                    href="https://open-platform.theguardian.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    The Guardian
                  </a>.
                  <span className="replace">
                    {" "}
                    Please note, the archive is{" "}
                    <span style={{ fontFamily: "BoldWide" }}>neither</span> designed
                    to mediate digital/physical repatriation, aid in
                    provenance research,{" "}
                    <span style={{ fontFamily: "BoldWide" }}>nor</span> does it
                    imply or confer ownership or rights to the documented
                    artifacts.
                  </span>
                </div>
                <div
                  className="mobilewords boldwords"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span style={{ fontFamily: "boldWide" }}>
                    LANGUAGE ACCESS
                  </span>
                </div>
                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0rem",
                    marginBottom: "2rem",
                  }}
                >
                  To access the{" "}
                  <span className="boldwide">Metadata Memoir</span> in Nigerian
                  Pidgin English
                  <sup>
                    <Link
                      to="ref56"
                      smooth={true}
                      duration={500}
                      id="note56"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioBook.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioBook.play();
                        }
                      }}
                    >
                      56
                    </Link>
                  </sup>
                  , visit{" "}
                  <a
                    className="zigzag-border "
                    href="https://pidgin.beninbronzes.xyz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="boldwords">pidgin.beninbronzes.xyz</span>
                  </a>
                  . Alternatively, you can access the pidgin portal by clicking
                  the <span className="replace">[</span>
                  <span style={{ fontFamily: "boldWide" }}>PG</span>
                  <span className="replace">]</span> icon located at the upper
                  right-hand section of the navigation menu. Please note, my
                  proficiency in Bini language is limited, therefore, the{" "}
                  <span className="boldwide">Metadata Memoir</span> is currently
                  unavailable in Bini.
                </div>
                
                <div
                  className="mobilewords "
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span style={{ fontFamily: "boldWide" }}>HOW TO CITE</span>
                </div>
                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                  handleReplace={handleReplace}
                >
                  The <span className="boldwide">Metadata Memoir</span> is
                  licensed under the Creative Commons
                  Attribution-NonCommercial-ShareAlike 3.0 License. You can find
                  detailed terms{" "}
                  <a
                    className="zigzag-border "
                    href="https://creativecommons.org/licenses/by-nc-sa/3.0/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="boldwords">here</span>
                  </a>
                  . If you use <span className="replace">/</span>share excerpts
                  or the entire archive, please ensure you attribute the artist,{" "}
                  <a
                    className="zigzag-border "
                    href="https://minneatairu.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="boldwords">Minne Atairu</span>
                  </a>
                  . For academic citations, refer to the following:
                  <br />
                  <br />
                  <span>
                    Atairu, M. (2023). The Metadata Memoir: A Decentralized
                    Archive for Reclaimed Benin Bronzes.
                  </span>{" "}
                  <span
                    className="replace boldwords"
                    style={{
                      textDecoration: "underline dashed",
                    }}
                  >
                    https://beninbronzes.xyz
                  </span>
                </div>
                <div
                  className="mobilewords boldwords"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span style={{ fontFamily: "boldWide" }}>
                  AI USAGE
                  </span>
                </div>
                <div
                  className="mobilewords"
                  style={{
                    marginTop: "0",
                    marginBottom: "2rem",
                    fontFamily: "symbols",
                  }}
                  handleReplace={handleReplace}
                >
                  The frontend is partially designed with Javascript generated with ChatGPT.</div>
                <div id="footnote-section">
                  <div
                    className="mobilewords "
                    style={{
                      marginTop: "2rem",
                      marginBottom: "0rem",
                    }}
                  >
                    <span style={{ fontFamily: "boldWide" }}>NOTES</span>
                  </div>
                  <ol
                    className="footnotegroup replace"
                    style={{ fontFamily: "symbols" }}
                  >
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref1"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          1
                        </span>
                        <Link
                          className="replace"
                          to="note1"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          The Metadata Memoir originated from my interest in
                          monitoring the repatriation status of Benin Bronzes,
                          which I included in the training data for my StyleGAN
                          research titled IGUN. The repatriation status of these
                          objects are available on the{" "}
                          <span className="boldwords">
                            <a
                              className="zigzag-border"
                              href="https://minneatairu.beninbronzetracker.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Benin Bronze Tracker
                            </a>
                          </span>{" "}
                          - an online repository where I logged all Benin
                          Bronzes involved in repatriation negotiations. This
                          includes records of deinstallation, successful
                          repatriation, and those currently awaiting
                          repatriation. While the tracker has proven to be
                          efficient in providing invaluable data and insights,
                          its current framework, reliant on manual data entry is
                          laborious and susceptible to significant time lags. To
                          mitigate these limitations, I designed the Metadata
                          Memoir, a semi-automated system with a sole purpose
                          <span className="replace">:</span> to document
                          reclaimed Benin Bronzes.
                        </Link>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref replace"
                        variant="body2"
                        id="ref2"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          2
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note2"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          I choose to employ the terms 'reclaimed' or
                          'repossessed', rather than 'repatriated' to underscore
                          the dedication of folks who tirelessly campaign for
                          the return of the Benin Bronzes.{" "}
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref3"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          3
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note3"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See: Eyo, E. (1997). The dialects of definitions:"
                          Massacre" and" sack" in the history of the Punitive
                          Expedition. African arts, 30(3), 34.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref4"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          4
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note4"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: Bacon, R. H., & Overend, W. H. (1897). Benin: The
                          city of blood. (No Title).
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref5"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          5
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note5"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See: Coombes, A. E. (1996). Ethnography, popular
                          culture and institutional power: narratives of Benin
                          culture in the British Museum, 1897-1992.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref6"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          6
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note6"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: Read, C. H., & Dalton, O. M. (1898). Works of art
                          from Benin City. The Journal of the Anthropological
                          Institute of Great Britain and Ireland, 27, 362-382.
                        </Link>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref7"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          7
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note7"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See: Eyo, E. (1997). The dialects of definitions:"
                          Massacre" and" sack" in the history of the Punitive
                          Expedition. African arts, 30(3), 34.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref8"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          8
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note8"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: Nevadomsky, J., Putova, B., & Soukup, V. (2014).
                          Benin art and casting technologies.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref9"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          9
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note9"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See:{" "}
                          <a
                            className="zigzag-border"
                            href="https://issuu.com/tropenmuseum/docs/2021_provenance_2__benin__e-book"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="boldwords">Provenance series</span>
                          </a>{" "}
                          by Museum Volkenkunde
                        </Link>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref10"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          10
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note10"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See: Barkan, E. (1997). Aesthetics and evolution:
                          Benin art in Europe. African Arts, 30(3), 36.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref11"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          11
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note11"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: Bodenstein, F. (2021). The Global Market
                          Trajectories of Two Brass Leopards from Benin City
                          (1897-1953). Reading Objects in the Contact Zone, 133.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref12"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          12
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note12"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: Bodenstein, F. [2022]. Getting the Benin Bronzes
                          Back to Nigeria. Contested Holdings: Museum
                          Collections in Political, Epistemic and Artistic
                          Processes of Return, 14, 220.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref13"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          13
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note13"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: Bernet, S. P. (1980). Catalogue of a collection
                          of Benin works of art: the property of a European
                          private collector which will be sold by auction by
                          Sotheby Parke Bernet & Co. (No Title).
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref14"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          14
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note14"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: auction results for An Extremely Fine Benin
                          Bronze Head at Christie's online
                          https://www.christies.com/en/lot/lot-316591
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref15"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          15
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note15"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See: Hartman, S. (2019). Wayward lives, beautiful
                          experiments: Intimate histories of riotous Black
                          girls, troublesome women, and queer radicals. WW
                          Norton & Company.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref16"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          16
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note16"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See: Hartman, S. (2021). Intimate history, radical
                          narrative. The Journal of African American History,
                          106(1), 127-135.
                        </Link>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref17"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          17
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note17"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          The hereditary art guilds (Igbesanwman, Igun Eronmwon,
                          Igun Ematon, Owina n'Ido, Owina, Isekpokin) of Benin
                          form an integral part of the rich cultural and
                          artistic heritage of the Benin Kingdom. These guilds
                          are centuries-old institutions that have played a
                          crucial role in preserving and transmitting artistic
                          knowledge and skills across generations.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref18"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          18
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note18"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Benin Kingdom was located in what is now known as
                          present-day Nigeria, specifically in the southern
                          region. The kingdom was centered around its capital,
                          Edo, which is now known as Benin City in Edo State.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref19"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          19
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note19"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See{" "}
                          <a
                            className="zigzag-border"
                            href="https://www.metmuseum.org/art/collection/search/316555"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="boldwords">altar bell</span>
                          </a>{" "}
                          utilized for summoning ancestors.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref20"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          20
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note20"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See{" "}
                          <a
                            className="zigzag-border"
                            href="https://www.britishmuseum.org/collection/object/E_Af1898-0115-31"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="boldwords">mudfish</span>
                          </a>{" "}
                          symbolizing the Oba
                          <span style={{ fontFamily: "symbols" }}>'</span>s
                          ability to travel across parallel worlds.{" "}
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref21"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          21
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note21"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See{" "}
                          <a
                            className="zigzag-border"
                            href="https://www.metmuseum.org/art/collection/search/310282"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="boldwords">
                              commemorative head
                            </span>
                          </a>{" "}
                          of Iyoba Idia.{" "}
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref22"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          22
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note22"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See{" "}
                          <a
                            className="zigzag-border"
                            href="https://www.metmuseum.org/art/collection/search/310752"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="boldwords">plaque</span>
                          </a>{" "}
                          depicting Equestrian Oba and Attendants.
                        </Link>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref23"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          23
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note23"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          Palm oil, extracted from the fruit of the oil palm
                          tree, was a highly sought-after commodity due to its
                          indispensable role in early mechanical processes. The
                          British, recognizing its versatility and economic
                          potential, avidly pursued the acquisition of palm oil.
                          Its applications extended across various sectors,
                          serving as a vital ingredient in soap production, the
                          manufacturing of margarine, the development of machine
                          lubricants, and candle production.{" "}
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref24"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          24
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note24"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See{" "}
                        </Link>
                        &nbsp;
                        <a
                          className="zigzag-border"
                          href="https://www.britishmuseum.org/collection/object/E_Af1954-23-296-a-b"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="boldwords">brass container</span>
                        </a>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref25"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          25
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note25"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See{" "}
                        </Link>
                        &nbsp;
                        <a
                          className="zigzag-border"
                          href="https://www.britishmuseum.org/collection/object/E_Af1944-04-63"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="boldwords">
                            coral beaded corslet
                          </span>
                        </a>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref26"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          26
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note26"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See
                        </Link>
                        &nbsp;
                        <a
                          className="zigzag-border"
                          href="https://www.britishmuseum.org/collection/object/E_Af1954-23-310_1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="boldwords">
                            wooden coiled mudfish box{" "}
                          </span>
                        </a>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref27"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          27
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note27"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See
                        </Link>
                        &nbsp;
                        <a
                          className="zigzag-border"
                          href="https://www.britishmuseum.org/collection/object/E_Af1954-23-277"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="boldwords">
                            commemorative terracotta head{" "}
                          </span>
                        </a>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref28"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          28
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note28"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Oba Ovonramwen Nogbaisi{" "}
                          <span className="replace">
                            [1845-1914] was the 38th Oba [King]
                          </span>{" "}
                          of the Benin Kingdom, reigning from 1888 to 1897.
                        </Link>
                      </Typography>
                      <Typography
                        handleReplace={handleReplace}
                        style={{ marginLeft: "3.2vw" }}
                        className="replace citational-justice"
                        variant="body2"
                      >
                        <span className="boldwords">See also</span>
                        <span className="replace">
                          : Rotimi, O. [1974]. Ovonramwen Nogbaisi [Benin City].
                        </span>
                      </Typography>
                      <Typography
                        handleReplace={handleReplace}
                        style={{ marginLeft: "3.2vw" }}
                        className="replace citational-justice"
                        variant="body2"
                      >
                        <span className="boldwords">See also</span>
                        <span className="replace">
                          : Yerima, A. [1998]. The Trials of Oba Ovonramwen.
                        </span>
                      </Typography>
                      <Typography
                        handleReplace={handleReplace}
                        style={{ marginLeft: "3.2vw" }}
                        className="replace citational-justice"
                        variant="body2"
                      >
                        <span className="boldwords">See also</span>
                        <span className="replace">
                          : Igbafe, P. A. [1967]. Oba Ovonramwen and the fall of
                          Benin. Tarikh, 2[2].
                        </span>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref29"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          29
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note29"
                          smooth={true}
                          duration={500}
                          handleReplace={handleReplace}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          See: institutional estimate according to a 2021 Global
                          survey of Benin Bronzes via
                          <span className="boldwords">
                            {" "}
                            <a
                              className="zigzag-border"
                              href="https://www.theartnewspaper.com/2021/04/29/global-survey-where-in-the-world-are-the-benin-bronzes"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              The Art Newspaper
                            </a>
                          </span>
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref30"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          30
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note30"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                          style={{ fontFamily: "symbols" }}
                        >
                          The term Benin Bronzes is an art historical
                          categorization, not a descriptor of the material
                          makeup of the corpus. The collection, despite
                          comprising various materials such as brass, ivory,
                          wood, leather, terracotta, iron, and coral, is
                          universally designated as Benin Bronzes. This is
                          largely due to the dominance of bronze-based iconic
                          works [such as commemorative heads and plaques] in art
                          historical discourse.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref31"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          31
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note31"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          This date is based on the last updated record on this
                          archive.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref32"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          32
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note32"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          This number originates from data obtained via a news
                          API, synchronized with the smart contract. It is
                          crucial to mention that this figure is merely an
                          approximation and may not represent the total count of
                          reclaimed Benin Bronzes. Specifically, it might
                          exclude bronzes that were returned but not officially
                          broadcasted or made public via online platforms.
                        </Link>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref33"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          33
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note33"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Three News APIs - New York Times, The Guardian
                          Newspaper, and a general News API - are configured
                          with specific keywords related to the repatriation
                          process. These APIs enable the smart contract to
                          search and filter press releases and articles about
                          the reclamation of Benin Bronzes.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref34"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          34
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note34"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Each on-chain record is designated a four-part
                          identifier, exemplified by the following format
                          <span className="replace">: </span>{" "}
                          <span className="boldwords">MM RBB 16-21</span>
                        </Link>
                      </Typography>
                      <Typography
                        handleReplace={handleReplace}
                        style={{ marginLeft: "3.2vw" }}
                        className="replace citational-justice"
                        variant="body2"
                      >
                        <span className="boldwords">MM</span>
                        <span className="replace">: </span>
                        Abbreviation for <span className="boldwords">M</span>
                        etadata <span className="boldwords">M</span>emoir
                      </Typography>
                      <Typography
                        handleReplace={handleReplace}
                        style={{ marginLeft: "3.2vw" }}
                        className="replace citational-justice"
                        variant="body2"
                      >
                        <span className="boldwords">RBB</span>
                        <span className="replace">: </span>
                        Abbreviation for <span className="boldwords">R</span>
                        eclaimed <span className="boldwords">B</span>enin{" "}
                        <span className="boldwords">B</span>ronze
                      </Typography>

                      <Typography
                        handleReplace={handleReplace}
                        style={{ marginLeft: "3.2vw" }}
                        className="replace citational-justice"
                        variant="body2"
                      >
                        <span className="boldwords replace">
                          Sequence number for a subcollection [16-21]
                        </span>
                        <span className="replace">:</span> Here, a subcollection
                        denotes a set of objects that were reclaimed from an
                        institution on a specific date. The numbers preceding
                        the dash represent the randomly assigned numerical order
                        of an object within the subcollection, while the numbers
                        following the dash indicate the total number of objects
                        comprising the subcollection. For instance, in the
                        example <span className="boldwords">16-21</span>, the
                        object in question would be identified as the 16th item
                        among a group of 21 reclaimed objects.{" "}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref35"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          35
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note35"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Here, metadata is defined as a set of select core data
                          points associated with each reclaimed Benin Bronze.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref36"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          36
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note36"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Dynamic fields are necessary because press releases
                          may not always provide object-specific details, and in
                          some cases may contain inaccuracies. In addition, the
                          lack of a centralized database for reclaimed Benin
                          Bronzes compounds this issue, since institutions
                          publish full object listings on arbitrary web pages.
                          Thus, dynamic fields act as a necessary safety
                          mechanism to ensure accuracy.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref37"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          37
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note37"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Press releases from repatriating institutions provide
                          a credible, officially sanctioned source of
                          information, thus, making them a semi-reliable data
                          source for the Metadata Memoir. However, it is crucial
                          to acknowledge that the lack of standardization
                          [variations in formatting, language usage, and level
                          of detail] in press releases can pose challenges for
                          automated data collection processes.
                        </Link>
                      </Typography>
                    </li>

                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref38"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          38
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note38"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          There are varying perspectives concerning the nature
                          of the tax obligation that provoked my ancestors'
                          dissent.
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref39"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          39
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note39"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Osundare, N. [2002]. Pages from the book of the sun:
                          New and selected poems. Africa World Press.{" "}
                        </Link>
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref40"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          40
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note40"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Yerima, A. [1998]. The Trials of Oba Ovonramwen.{" "}
                        </Link>{" "}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref41"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          41
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note41"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          See Ola Rotimi's commentary provided in the background
                          to the play titled 'Ovonramwen Nogbaisi.'
                        </Link>{" "}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref42"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          42
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note42"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Rotimi, O. [1974]. Ovonramwen Nogbaisi [Benin City].{" "}
                        </Link>{" "}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className="footnoteref"
                        variant="body2"
                        id="ref43"
                      >
                        <span
                          className="footnoterefnumber"
                          style={{ marginLeft: "0.5vw", fontFamily: "symbols" }}
                        >
                          43
                        </span>{" "}
                        <Link
                          className="replace"
                          to="note43"
                          smooth={true}
                          duration={500}
                          onClick={() => {
                            // const gvolume = setvolume();
                            // audioBook.volume = gvolume ? 1 : 0;
                            if (isVolumeOn) {
                              audioBook.play();
                            }
                          }}
                        >
                          Enwezor, O.,{" "}
                          <span style={{ fontFamily: "symbols" }}>
                            & Okeke-Agulu, C. (2009). Contemporary African art
                            since 1980 (p. 10). Bologna:
                          </span>{" "}
                          Damiani.{" "}
                        </Link>{" "}
                      </Typography>
                    </li>
                  </ol>
                </div>

                <div
                  className="mobilewords appendix-main"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "0rem",
                  }}
                >
                  <span style={{ fontFamily: "boldWide" }}>APPENDIX</span>
                </div>

                <div
                  className="appendix"
                  variant="subtitle1"
                  style={{
                    marginTop: "1vw",
                    left: "0",
                    fontFamily: "UprightBoldNarrow",
                  }}
                >
                  LIST OF 3D OBJECTS{" "}
                  <span
                    style={{
                      fontFamily: "symbols",
                      paddingLeft: "10px",
                    }}
                  >
                    {" "}
                    [
                  </span>
                  CLICK TO VIEW<span className="replace">]</span>
                </div>
                <div
                  className="mobilewords boldwords"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <IframeGridComponent lan={lan} />
                </div>

                <div
                  className="appendix"
                  variant="subtitle1"
                  style={{
                    marginTop: "1vw",
                    left: "0",
                    fontFamily: "UprightBoldNarrow",
                  }}
                >
                  DATA DISCLAIMER{" "}
                </div>
                <ol className="chart-info-parent">
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", paddingTop: "15px" }}
                    >
                      The search automation is powered by data provided by The
                      New York Times, The Guardian, and a general news API.{" "}
                    </li>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", paddingTop: "15px" }}
                    >
                      Keywords guiding the search automation were derived from
                      an analysis of 120 articles mentioning the Benin Bronzes
                      across 10 news publications from 2019 to 2022.
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", paddingTop: "15px" }}
                    >
                      The archival listing is dynamically shuffled upon each
                      webpage refresh, ensuring that items never appear in a
                      predetermined order.
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", paddingTop: "15px" }}
                    >
                      The{" "}
                      <a
                        className="zigzag-border"
                        href="https://www.cp3c.org/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="boldwords german">
                          German Government
                        </span>
                      </a>{" "}
                      has assumed responsibility for the repatriation of Benin
                      Bronzes from all German museums. Consequently, all related
                      records in this archive bear the institutional label{" "}
                      <span className="boldwords">Germany</span>, rather than
                      specific institutional names. This measure was integrated
                      into the protocol as a safety mechanism. It was adopted
                      following a review of news reports about the Benin Bronzes
                      and German museums, which consistently referred to the
                      country - <span className="boldwords">Germany</span>,
                      instead of citing individual institutions.
                    </li>{" "}
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", paddingTop: "15px" }}
                    >
                      All metrics are based on the data captured by the smart
                      contract. For example, material ratios are calculated
                      using total reclaimed object count and corresponding
                      material type frequency.
                    </li>
                  </Typography>
                  <Typography>
                    <li className="chart-info  replace"                       style={{  paddingTop: "15px" }}
>
                      Real-time updates are delayed at least 1 minute.
                    </li>
                  </Typography>
                  <Typography>
                    <li className="chart-info  replace"     style={{  paddingTop: "15px" }}>
                      All material classes are based on publicly available
                      information.
                    </li>
                  </Typography>
                  <Typography>
                    <li className="chart-info replace"      style={{  paddingTop: "15px" }}>
                      The <span className="boldwords">Undisclosed</span> label
                      denotes that the repatriating institution is yet to
                      publish detailed data.
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1.5",  paddingTop: "15px"  }}
                    >
                      All 3D models are publicly available files released by
                      various institutions.{" "}
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", paddingTop: "15px" }}
                    >
                      <span className="boldwords">Dynamic data</span> within
                      paragraph texts are visually indicated by animated text
                      shadows. For verification
                      <span className="replace">:</span> hover over the text to
                      reveal a tooltip labelled{" "}
                      <span className="boldwords">Dynamic Entry</span>.
                    </li>
                  </Typography>
                  <Typography>
                    <li
                      className="chart-info replace"
                      style={{ lineHeight: "1", paddingTop: "15px" }}
                    >
                      All dates are recorded in GMT+1 timezone.
                    </li>
                  </Typography>
                </ol>
              </Typography>
            </BiniStyler>
          )}
        </div>
      </Paper>
      <div>
        {showOverlay && (
          <div
            className="overlay"
            onClick={handleCloseOverlay} // Add the onClick event here
          >
            {" "}
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay(false);
                // const gvolume = setvolume();
                // audioClose.volume = gvolume ? 1 : 0;
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Head of an Oba, 1500-1550, Africa, Benin - copy"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/92c5dd1e25a94380881be37518addc23/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "100%", height: "100%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Oba Head, 1500-1550, wey currently dey di Sainsbury Center for
                  University of East Anglia, Norwich
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Head of an Oba, 1500-1550, currently held at the Sainsbury
                  Center, University of East Anglia, Norwich
                </Typography>
              )}
            </div>
          </div>
        )}
        {showOverlay2 && (
          <div className="overlay" onClick={handleCloseOverlay2}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay2(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Second 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/ad83d4c2eff349f0be6c681a053fbc06/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "100%", height: "100%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Plaque wey dey show Portuguese soja, 1500-1560, wey currently
                  dey di Sainsbury Center for University of East Anglia, Norwich
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Plaque fragment representing a Portuguese soldier, 1500-1560,
                  currently held at the Sainsbury Center, University of East
                  Anglia, Norwich
                </Typography>
              )}
            </div>
          </div>
        )}
        {showOverlay3 && (
          <div className="overlay" onClick={handleCloseOverlay3}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay3(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Third 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/df58633f6fc14778a3459618dda6fb34/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "100%", height: "100%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Dis na Oba Staff, 1500-1799, wey currently dey di Sainsbury
                  Center for University of East Anglia, Norwich
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Royal Sceptre, 1500-1799, currently held at the Sainsbury
                  Center, University of East Anglia, Norwich
                </Typography>
              )}
            </div>
          </div>
        )}
        {showOverlay4 && (
          <div className="overlay" onClick={handleCloseOverlay4}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay4(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Third 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/5679e8e1cd4c40099923decf68c249da/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "120%", height: "120%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Ovonramwen<span style={{ fontFamily: "symbols" }}>' </span>s
                  Throne wey currently dey di Varldskulturmuseerna, Sweden
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Ovonramwen<span style={{ fontFamily: "symbols" }}>' </span>s
                  Throne currently held at the Varldskulturmuseerna, Sweden
                </Typography>
              )}
            </div>
          </div>
        )}
        {showOverlay5 && (
          <div className="overlay" onClick={handleCloseOverlay5}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay5(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Third 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/7ff54ca6a9db48f0b0c4c405b2c41439/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "120%", height: "120%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Pesin wey dey siddon wey currently dey di
                  Varldskulturmuseerna, Sweden
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Seated human figure currently held at the
                  Varldskulturmuseerna, Sweden
                </Typography>
              )}
            </div>
          </div>
        )}
        {showOverlay6 && (
          <div className="overlay" onClick={handleCloseOverlay6}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay6(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Third 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/e107c1a5c7584d6093c0528a702cc59a/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "120%", height: "120%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Altar Tusk, 1775-1777, wey currently dey di Minneapolis
                  Institute of Art
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Altar Tusk, 1775-1777, currently held at the Minneapolis
                  Institute of Art
                </Typography>
              )}
            </div>
          </div>
        )}

        {showOverlay7 && (
          <div className="overlay" onClick={handleCloseOverlay7}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay7(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Third 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/1a71cce789dc479baaf27f46d8a9447e/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "120%", height: "120%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Terracotta Head, 14th-16th century, wey currently dey di Museo
                  de Arte Africano Arellano Alonso, Spain
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Terracotta Head, 14th-16th century, currently held at Museo de
                  Arte Africano Arellano Alonso, Spain
                </Typography>
              )}
            </div>
          </div>
        )}
        {showOverlay8 && (
          <div className="overlay" onClick={handleCloseOverlay8}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay8(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Third 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/92885bc5ea574560978dca9efd71e6a8/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "120%", height: "120%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Udo styel head, 15th - 16th century, wey currently dey di
                  Museum of Archaeology and Anthropology, England
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Udo style head, 15th - 16th century, currently held at Museum
                  of Archaeology and Anthropology, England
                </Typography>
              )}
            </div>
          </div>
        )}
        {showOverlay9 && (
          <div className="overlay" onClick={handleCloseOverlay9}>
            <button
              className="close-button"
              onClick={() => {
                setShowOverlay9(false);
                if (isVolumeOn) {
                  audioClose.play();
                }
              }}
            >
              Close
            </button>
            <iframe
              title="Third 3D View"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-trackin="true"
              execution-while-out-of-viewport="true"
              execution-while-not-rendered="true"
              web-share="true"
              src="https://sketchfab.com/models/033a88e4a5c04a5aa856ed6c09eeca93/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0"
              style={{ width: "120%", height: "120%" }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {lan && lan === "PG" ? (
                <Typography className="bronzecaption" color="textSecondary">
                  Leopard Mask, 15th-19th century, wey currently dey di The Hunt
                  Museum, Ireland
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="about bronzecaption"
                  id="about_secondary"
                >
                  Leopard Mask, 15th-19th century, currently held at The Hunt
                  Museum, Ireland
                </Typography>
              )}
            </div>
          </div>
        )}
      </div>
      <ScrollTopButton lan={lan} isVolumeOn={isVolumeOn} />
    </>
  );
}

export default About;

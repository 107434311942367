import React from 'react';

function numberToWords(num) {
  const belowTen = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const belowTwenty = ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const belowHundred = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

  if (num < 10) return belowTen[num];
  if (num < 20) return belowTwenty[num - 10];
  if (num < 100) return belowHundred[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + belowTen[num % 10] : "");
  if (num < 1000) return belowTen[Math.floor(num / 100)] + " hundred" + (num % 100 !== 0 ? " and " + numberToWords(num % 100) : "");
  if (num < 10000) return belowTen[Math.floor(num / 1000)] + " thousand" + (num % 1000 !== 0 ? " " + numberToWords(num % 1000) : "");

  return "";
}

const TheftYears = () => {
  const currentYear = new Date().getFullYear();
  const yearsAfterTheft = currentYear - 1897;
  const yearsInWords = numberToWords(yearsAfterTheft);

  return (
    <span>
      {yearsInWords.charAt(0).toUpperCase() + yearsInWords.slice(1)} years later
    </span>
  );
};

export default TheftYears;

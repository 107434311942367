import React, { useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import FilterSound from "../sounds/menu.mp3";
import SupSound from "../sounds/backtotop.mp3";
import { setvolume } from "./Navbar";

const audioBook = new Audio(SupSound);


const CustomTooltip = ({ active, payload, label, isPer, handleReplace }) => {
  if (active && payload && payload.length) {
    if (handleReplace) {
      handleReplace();
    }
    const value = `${payload[0].value}${isPer ? "%" : ""}`;
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "transparent",     outline: "none!important", }}
      >
        <p
          style={{
            textTransform: "uppercase!important",
            textShadow: "0 0 30px #cf3031",
            fontSize: "4rem",
            fontFamily: "UprightBoldNarrow!important",
            backgroundColor: "transparent!important",
          }}
          className="replace"
        >{`${label}: ${value}`}</p>
      </div>
    );
  }

  return null;
};

const AllMaterialsPlot = ({ myData, label, isPer, lan, handleReplace }) => {
  const sortedData = [...myData].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    handleReplace();
  }, [myData, isPer, lan]);

  return (
    <>
      <h3 style={{ margin: "20px 0 0 20px" }}>{label}</h3>
      <div className="chart-container">
      <ResponsiveContainer
        className="all--materials--chart"
        width="100%"
        height="100%"
      >
        <AreaChart
          data={sortedData} // Use sortedData instead of myData
          margin={{ top: 10, right: 0, left: 0, bottom: 10 }}
          style={{ width: "100vw" }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1B892B" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#1B892B" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2B65EC" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2B65EC" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="name"
            strokeDasharray="3 3"
            fontSize={20}
            stroke="#E0DFD3"
          />
          <YAxis
            className="replace"
            fontFamily="symbols"
            tickFormatter={(value) => `${value}${isPer ? "%" : "%"}`}
            strokeDasharray="3 3"
            fontSize={20}
            stroke="#E0DFD3"
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#E0DFD3" />
          <Tooltip
            className="replace"
            content={
              <CustomTooltip isPer={isPer} handleReplace={handleReplace} />
            }
    
            contentStyle={{
              backgroundColor: "transparent",
              border: "none",
              textShadow: "0 0 20px #f8a100",
            }}
          />
          <Area
            type="monotone"
            dataKey="amt"
            stroke="#E0DFD3"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
      </div>
      <div className="chart--caption" handleReplace={handleReplace} lan={lan}>
        <p style={{ margin: "0" }}>
          {lan && lan === "PG" ? (
            <span className="replace">
              Informate about di different different kain materials wey dem use
              take make Benin Bronzes [2021-reach naw]
            </span>
          ) : (
            <span className="replace">
              Chart showing the percentage distribution of materials documented
              on this archive [2021-present]
            </span>
          )}
        </p>
        <p style={{ margin: "0" }}>
          {lan && lan === "PG" ? (
            <p className=" replace" style={{ margin: "0" }}>
              Di last time wey e update automatically na{" "}
              <span className="lastUpdate replace"></span>
            </p>
          ) : (
            <p className=" replace" style={{ margin: "0" }}>
              Data as of <span className="lastUpdate replace"></span>
            </p>
          )}
        </p>
        <p style={{ margin: "0" }}>
          {lan && lan === "PG" ? (
            <>
              <span className=" replace">Abeg, read di data tori </span>
              <a
                className="zigzag-border"
                href="#datatori"
                onClick={() => {
                  const gvolume = setvolume();
                  audioBook.volume = gvolume ? 1 : 0;
                  audioBook.play();
                }}
              >
                here
              </a>
            </>
          ) : (
            <>
              <span className=" replace">Read data details </span>
              <a
                className="zigzag-border"
                href="#datadetails"
                onClick={() => {
                  const gvolume = setvolume();
                  audioBook.volume = gvolume ? 1 : 0;
                  audioBook.play();
                }}
              >
                here
              </a>
            </>
          )}
        </p>
      </div>
    </>
  );
};

export default AllMaterialsPlot;

import { ImageListItem } from "@material-ui/core";
import { setYear } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import FilterSound from "../sounds/menu.mp3";
import SupSound from "../sounds/backtotop.mp3";
import { setvolume } from "./Navbar";

const audioFilter = new Audio(FilterSound);
const audioBook = new Audio(SupSound);

const CustomTooltip = ({
  active,
  payload,
  label,
  isPer,
  materialName,
  handleReplace,
  activePlot,
}) => {
  if (active && payload && payload.length) {
    if (handleReplace) {
      handleReplace();
    }
    console.log(payload);
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "transparent", outline: "none!important" }}
      >
        {payload.map((item, index) => (
          <p
            key={`${index} ${item.value}`}
            style={{
              textTransform: "uppercase",
              textShadow: "0 0 30px #cf3031",
              fontSize: "4rem",
             
              backgroundColor: "transparent!important",
            }}
            className="replace"
          >
            {item.dataKey}: {item.value} {materialName}{" "}
            {item.value === 1 ? "object" : "objects"}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const AreaPlot = ({
  myData,
  updates,
  materials,
  label,
  isPer,
  isMulti,
  years,
  materialName,
  handleReplace,
  activePlot,
}) => {
  return (
    <>
      <p className="chart--material--title" style={{ margin: "20px 0 0 20px" }}>
        {label}
      </p> <div className="chart-container">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={myData}
          margin={{ top: 10, right: 0, left: 0, bottom: 10 }}
          style={{ width: "100vw", height:"100vh" }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1B892B" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#1B892B" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2B65EC" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2B65EC" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="name"
            strokeDasharray="3 3"
            fontSize={20}
            stroke="#E0DFD3"
          />
          <YAxis
            tickFormatter={(value) => `${value}${isPer ? "%" : ""}`}
            strokeDasharray="3 3"
            fontSize={20}
            stroke="#E0DFD3"
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#E0DFD3" />
          <Tooltip
            content={
              <CustomTooltip
                isPer={isPer}
                activePlot={activePlot}
                materialName={materialName}
                handleReplace={handleReplace}
              />
            }
     
            contentStyle={{
              backgroundColor: "transparent",
              border: "none",
              textShadow: "0 0 20px #f8a100",
            }}
          />

          {/* <Legend /> */}
          {isMulti ? (
            <>
              {years.map((year, index) => {
                return (
                  <Area
                    key={year}
                    type="monotone"
                    dataKey={year}
                    stroke="#E0DFD3"
                    fillOpacity={1}
                    fill={index % 2 === 0 ? "url(#colorUv)" : "url(#colorPv)"}
                  />
                );
              })}
            </>
          ) : (
            <Area
              type="monotone"
              dataKey="amt"
              stroke="#E0DFD3"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          )}

          {/* <Area
    type="monotone"
    dataKey="pv"
    stroke="#82ca9d"
    fillOpacity={1}
    fill="url(#colorPv)"
  /> */}
        </AreaChart>
      </ResponsiveContainer></div>
    </>
  );
};

export default function App({
  myData,
  updates,
  materials,
  lan,
  handleReplace,
}) {
  const [ind, setInd] = useState([]);
  const [ind2D, setInd2D] = useState([]);
  const [yearsT, setYears] = useState([]);
  const [activePlot, setActivePlot] = useState(0);
  const [plotVisibility, setPlotVisibility] = useState(
    Array(ind.length).fill(true)
  );

  useEffect(() => {
    if (updates && updates[0] && materials) {
      let temp = [];
      for (let m of materials) {
        temp.push({
          material: m,
          data: updates.filter((item) => {
            return item.material === m;
          }),
        });
      }
  
      let temp2D = [];
      let allYears = [];
      for (let y = 2021; y <= 2023; y++) {
        allYears.push(y.toString());
      }
      let allY = {};
      for (let m of temp) {
        let counts = {};
        let tempD = [];
        for (let i = 1; i < 13; i++) {
          counts[i] = 0;
        }
        for (let n of m.data) {
          counts[n.returned] = counts[n.returned] ? counts[n.returned] + 1 : 1;
        }
  
        let tempC = Object.keys(counts).map((key) => [key, counts[key]]);
        for (let o of tempC) {
          let d = new Date(o[0]);
          tempD.push({
            returned: o[0],
            year: `${o[0].length < 3 ? 0 : d.getFullYear()}`,
            name: `${d.toLocaleString("default", {
              month: "short",
            })}`,
            amt: o[1],
          });
        }
        tempD.sort((a, b) => {
          return new Date(a.returned) - new Date(b.returned);
        });
  
        let yearStruct = {};
        for (let y of allYears) {
          yearStruct[y] = 0;
        }
  
        let newT = tempD.map((item) => {
          let temp = { ...item, ...yearStruct };
          temp[item.year] += item.amt;
          delete temp["year"];
          delete temp["returned"];
          return temp;
        });
  
        let newNewT = [];
        let tempMonths = new Set(tempD.map((item) => item.name));
        let months = Array.from(tempMonths);
        for (let m of months) {
          let toWork = newT.filter((item) => item.name === m);
          let first = toWork[0];
          let keys = Object.keys(first);
          let t = {};
          for (let k of keys) {
            if (k === "name") {
              t[k] = first[k];
              continue;
            }
            for (let toW of toWork) {
              t[k] = t[k] ? (t[k] += toW[k]) : toW[k];
            }
          }
          newNewT.push(t);
        }
  
        temp2D.push(newNewT);
        allY = { ...allY, ...yearStruct };
      }
  
      setYears(allYears);
      setInd(temp);
      setInd2D(temp2D);
    }
  }, [updates, materials]);
  

  const [isPaused, setIsPaused] = useState(false);

  const handleTogglePause = () => {
    setIsPaused((prevIsPaused) => !prevIsPaused);
  };

  return (
    <>
      {/* Display individual plot */}
      <AreaPlot
        index={ind[activePlot]?.material}
        myData={ind2D[activePlot]}
        updates={updates}
        materials={materials}
        materialName={ind[activePlot]?.material}
        label={`Material - ${ind[activePlot]?.material}`}
        isMulti={true}
        years={yearsT} // Make sure this is an array of years
        handleReplace={handleReplace}
      />

      {/* Display buttons to toggle visibility of other plots */}
      <div
        className="toggle-buttons-loop"
        onClick={handleTogglePause}
        style={{
          margin: "0 0px!important",
        }}
      >
        <div
          className="toggle-buttons"
          style={{ animationPlayState: isPaused ? "paused" : "running" }}
        >
          {[...ind, ...ind].map((mat, index) => {
            const total = ind2D.reduce(
              (total, current) =>
                total + current.reduce((sum, { amt }) => sum + amt, 0),
              0
            );
            const materialCount = ind2D[index % ind.length].reduce(
              (acc, curr) => acc + curr.amt,
              0
            );
            const percentage = ((materialCount / total) * 100).toFixed(2);

            return (
              <button
                className="materialscroller replace"
                key={`${mat.material}-${index}`}
                onClick={() => {
                  setActivePlot(index % ind.length);
                }} // Modulo operator for circular array
                style={{
                  fontWeight:
                    index % ind.length === activePlot ? "bold" : "bold",
                  backgroundColor:
                    index % ind.length === activePlot ? "#E0DFD3" : "#000000",
               
                  color: index % ind.length === activePlot ? "#000000" : "#E0DFD3",
                  border: "dashed #E0DFD3",
                  margin: "0 10px",
                }}
              >
                {mat.material} <span style={{fontFamily:"uprightboldnarrow"}}>{percentage}</span> <span style={{fontFamily:"symbols"}}>%</span>
              </button>
            );
          })}
        </div>
      </div>

      <div
        className="chart--caption replace"
        handleReplace={handleReplace}
        lan={lan}
      >
        <p style={{ margin: "0" }}>
          {lan && lan === "PG" ? (
            <span className="replace">
              Chart wey dey show di percentage of each kind material dem dey use
              make Benin bronzes wey dey don return go Naija [2021-reach now]
            </span>
          ) : (
            <span className="replace">
              Chart showing the percentage distribution of reclaimed Benin
              Bronzes by material type [2021-present]
            </span>
          )}
        </p>

        <p style={{ margin: "0" }}>
          {lan && lan === "PG" ? (
            <p className=" replace" style={{ margin: "0" }}>
              Di last time wey e update automatically na{" "}
              <span className="lastUpdate replace"></span>
            </p>
          ) : (
            <p className="replace" style={{ margin: "0" }}>
              Data as of <span className="lastUpdate replace"></span>
            </p>
          )}
        </p>
        <p style={{ margin: "0" }}>
          {lan && lan === "PG" ? (
            <>
              <span className=" replace">Abeg, read di data tori </span>
              <a
                className="zigzag-border"
                href="#datatori"
                onClick={() => {
                  const gvolume = setvolume();
                  audioBook.volume = gvolume ? 1 : 0;
                  audioBook.play();
                }}
              >
                here
              </a>
            </>
          ) : (
            <>
              <span className=" replace">Read data details </span>
              <a
                className="zigzag-border"
                href="#datadetails"
                onClick={() => {
                  const gvolume = setvolume();
                  audioBook.volume = gvolume ? 1 : 0;
                  audioBook.play();
                }}
              >
                here
              </a>
            </>
          )}
        </p>
      </div>
    </>
  );
}

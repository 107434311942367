import React from 'react';

const styleBronzeVariations = (children) => {
  return React.Children.map(children, child => {
    if (typeof child === 'string') {
      const regex = /(Benin Bronzes?|Bini Bronzes?)/g;
      const parts = child.split(regex);

      return (
        <>
          {parts.map((part, index) => {
            if (regex.test(part)) {
              return <span key={index} style={{ fontFamily: 'UprightBoldNarrow' }}>{part}</span>;
            }
            return part;
          })}
        </>
      );
    }

    if (React.isValidElement(child) && child.props.children) {
      return React.cloneElement(child, {
        children: styleBronzeVariations(child.props.children),
      });
    }

    return child;
  });
};

const BronzeStyler = ({ children }) => {
  return <>{styleBronzeVariations(children)}</>;
};

export default BronzeStyler;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import xml2js from 'xml2js';
import { useParams } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '700px',
}


const XEBAY_SOA_SECURITY_APPNAME = process.env.REACT_APP_XEBAY_SOA_SECURITY_APPNAME;
const XEBAY_SOA_GLOBAL_ID = process.env.REACT_APP_XEBAY_SOA_GLOBAL_ID;;
const CORS_API_KEY = process.env.REACT_APP_CORS_API_KEY;
const XEBAY_API_IAF_TOKEN = process.env.REACT_APP_XEBAY_API_IAF_TOKEN;

const ProductDetail = () => {
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  const handleProductDetail = async () => {
    setIsLoading(true);
    axios.get(`https://proxy.cors.sh/https://open.api.ebay.com/shopping?callname=GetSingleItem&esponseencoding=XML&siteid=0&version=967&ItemID=${params.id}`, {
      headers: {
        'X-EBAY-API-IAF-TOKEN': XEBAY_API_IAF_TOKEN,
        'X-EBAY-SOA-SECURITY-APPNAME': XEBAY_SOA_SECURITY_APPNAME,
        'X-EBAY-SOA-GLOBAL-ID': XEBAY_SOA_GLOBAL_ID,
        'x-cors-api-key': CORS_API_KEY
      }
    })
      .then(response => {
        xml2js.parseString(response.data, (err, result) => {
          const item = result.GetSingleItemResponse.Item[0];
          setItem(item);
          setIsLoading(false);
        });
      })
      .catch(error => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    handleProductDetail();
  }, []);

  const images = item?.PictureURL?.map(el => {
    return {
      original: el,
      thumbnail: el,
    }
  }) ?? []


  return (
    <div>
      {isLoading && (
        <div style={{ padding: '10px' }}>
          Loading data please wait...
        </div>
      )}
      <h1>{item?.Title[0]}</h1>
      <ImageGallery items={images} />
    </div >
  );
};

export default ProductDetail;

import React, { useState } from 'react';

const IframeGridComponent = ({ lan }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentIframe, setCurrentIframe] = useState(null);

  const handleIframeClick = (iframe) => {
    setCurrentIframe(iframe);
    setShowModal(true);
  };

  const closeModal = () => {
    setCurrentIframe(null);
    setShowModal(false);
  };

const iframes = [
  { 
    src: 'https://sketchfab.com/models/92c5dd1e25a94380881be37518addc23/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption: lan && lan === "PG" ? "HEAD WEY DEY SHOW OBA, 1500-1550" : "Head of an Oba, 1500-1550",
    text: [      
      { 
        content: lan && lan === "PG" ? (
          <p className="beninbronze-name" style={{ margin: "0" }}>
            HEAD WEY DEY SHOW OBA
          </p>
        ) : (
          <p className="beninbronze-name" style={{ margin: "0" }}>
            Head of an Oba
          </p>
        )
      },
      { content: 'SAINSBURY CENTER, University of East Anglia', class: 'institution-name-main' },
    ] 
  },
  { 
    src: 'https://sketchfab.com/models/ad83d4c2eff349f0be6c681a053fbc06/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption:  lan && lan === "PG" ? "Plaque wey dey show Portuguese soja, 1550-1650" : "Plaque fragment Portuguese Soldier    , 1550-1650", 
    text: [
      { 
        content: lan && lan === "PG" ? (
          <p className="beninbronze-name" style={{ margin: "0" }}>
            Plaque wey dey show Portuguese soja
          </p>
        ) : (
          <p className="beninbronze-name" style={{ margin: "0" }}>Plaque fragment Portuguese Soldier
          </p>
        )
      },
      { content: 'SAINSBURY CENTER, University of East Anglia', class: 'institution-name-main' }
    ] 
  },
  { 
    src: 'https://sketchfab.com/models/df58633f6fc14778a3459618dda6fb34/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption: lan && lan === "PG" ? "Oba Staff, 1500-1799" : "Royal Sceptre, 1500-1799",
    
    text: [
      { 
        content: lan && lan === "PG" ? (
          <p className="beninbronze-name" style={{ margin: "0" }}>
            Oba Staff
          </p>
        ) : (
          <p className="beninbronze-name" style={{ margin: "0" }}>
Royal Scepter</p>
        )
      },
      { content: 'SAINSBURY CENTER, University of East Anglia', class: 'institution-name-main' },
       ] 
  },
   { 
    src: 'https://sketchfab.com/models/5679e8e1cd4c40099923decf68c249da/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption:  lan && lan === "PG" ? " OBA THRONE, 1800s" : "Oba's Throne, 1800s", 
    text: [
        { 
        content: lan && lan === "PG" ? (
          <p className="beninbronze-name" style={{ margin: "0" }}>
          OBA THRONE
          </p>
        ) : (
          <p className="beninbronze-name" style={{ margin: "0" }}>
Oba's Throne</p>
        )
      },
      { content: 'Varldskulturmuseerna, Sweden', class: 'institution-name-main' },
     ] 
  },
  { 
    src: 'https://sketchfab.com/models/7ff54ca6a9db48f0b0c4c405b2c41439/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption: lan && lan === "PG" ? " PESIN WEY SIDDON, No date" : "Seated human figure, No date", 
    text: [
      { 
        content: lan && lan === "PG" ? (
          <p className="beninbronze-name" style={{ margin: "0" }}>
          PESIN WEY SIDDON
          </p>
        ) : (
          <p className="beninbronze-name" style={{ margin: "0" }}>
Seated human figure</p>
        )
      },
      { content: 'Varldskulturmuseerna, Sweden', class: 'institution-name-main' },
       ] 
  },
  { 
    src: 'https://sketchfab.com/models/033a88e4a5c04a5aa856ed6c09eeca93/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption: 'Leopard Mask, 1500-1799', 
    text: [
      { content: 'Leopard Mask', class: 'beninbronze-name' },
      { content: 'The Hunt Museum, Ireland', class: 'institution-name-main' },
       ] 
  },
  { 
    src: 'https://sketchfab.com/models/1a71cce789dc479baaf27f46d8a9447e/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption: 'Terracotta head, 14th-16th century', 
    text: [
      { content: 'Terracotta head', class: 'beninbronze-name' },
      { content: 'Museo de Arte Africano Arellano Alonso, Spain', class: 'institution-name-main' },
        ] 
  },
  { 
    src: 'https://sketchfab.com/models/e107c1a5c7584d6093c0528a702cc59a/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
    caption: 'Altar Tusk, 1775-1777', 
    text: [
      { content: 'Altar Tusk', class: 'beninbronze-name' },
      { content: 'Minneapolis Institute of Art', class: 'institution-name-main' },
       ] 
  },
  { 
    src: 'https://sketchfab.com/models/92885bc5ea574560978dca9efd71e6a8/embed?autospin=1&scrollwheel=0&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_watermark_link=0&ui_watermark=0', 
      caption: lan && lan === "PG" ? " Udo head, 15th - 16th century" : "Udo style head, 15th - 16th century", 

      text: [
      { 
        content: lan && lan === "PG" ? (
          <p className="beninbronze-name" style={{ margin: "0" }}>
          Udo Head
          </p>
        ) : (
          <p className="beninbronze-name" style={{ margin: "0" }}>
Udo Style Head</p>
        )
      },
      { content: 'University of Cambridge, England', class: 'institution-name-main' },
        ] 
  },
];



  return (
    <div>
      <div className="iframe-grid">
        {iframes.map((iframe, index) => (
          <div key={index} className="iframe-container" onClick={() => handleIframeClick(iframe)}>
            {iframe.text.map((line, idx) => (
              <span key={idx} className={`iframe-text ${line.class}`}>{line.content}</span>
            ))}
          </div>
        ))}
      </div>
      {showModal && currentIframe && (
        <div className="modal-iframe" onClick={closeModal}>
          <span className="closeiframe" onClick={closeModal}>CLOSE</span>
          <iframe
            title="3D Model"
            frameBorder="0"
            allowFullScreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-trackin="true"
            execution-while-out-of-viewport="true"
            execution-while-not-rendered="true"
            web-share="true"
            src={currentIframe.src}
            style={{ width: '130%', height: '130%' }}
          ></iframe>
          <div className="iframe-caption">{currentIframe.caption}</div>
        </div>
      )}
    </div>
  );
};

export default IframeGridComponent;

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Typography,
  makeStyles,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Grid,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { ExpandMore } from "@material-ui/icons";
import axios from "axios";
import ScrollTopButton from "./ScrollTopButton";
import { useTheme } from "../themeContext";

import hoverSound from "../sounds/mmbody.mp3";

const notes = {
  "0039": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0040": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0041": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0042": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0043": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0044": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0045": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0046": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0047": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0048": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
  "0049": "Ethnologisches Museum of the Staatliche Museen Zu Berlin",
};

// Only lower case keys

const instituteNotes = {
  /* germany: "Institute Note 1",*/
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  rootFilter: {
    margin: "1vw 2vw",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "30vw",
  },
}));

const marks = [
  {
    value: 12,
    label: "12th ",
  },
  {
    value: 13,
    label: "13th ",
  },
  {
    value: 14,
    label: "14th",
  },
  {
    value: 15,
    label: "15th",
  },
  {
    value: 16,
    label: "16th",
  },
  {
    value: 17,
    label: "17th",
  },
  {
    value: 18,
    label: "18th",
  },
  {
    value: 19,
    label: "19th",
  },
];

function valuetext(value) {
  return `${value}`;
}

function containsNumberInRange(str, min, max) {
  const regex = /\d+/g;
  let match;
  while ((match = regex.exec(str))) {
    const number = parseInt(match[0]);
    if (number >= min && number <= max) {
      return true;
    }
  }
  return false;
}

function areArraysOfObjectsEqual(arr1, arr2) {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays to ensure the objects are in the same order
  arr1.sort();
  arr2.sort();

  // Iterate through the arrays and compare objects
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    // Use JSON.stringify to compare objects as strings
    if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
      return false;
    }
  }

  // If all objects are the same, return true
  return true;
}

function Update({
  handleReplace,
  fetchData,
  checkIp,
  updates,
  loading,
  institutions,
  materials,
  years,
  lan,
  baseUrl,
  isVolumeOn,
  audioFilter,
}) {
  const classes = useStyles();
  const { theme } = useTheme();

  // Filters
  const [filterInst, setFilterInst] = useState("All");
  const [filterYear, setFilterYear] = useState("All");
  const [filterMaterial, setFilterMaterial] = useState("All");
  const [rec, setRec] = useState(false);
  const [viewMode, setViewMode] = useState("list");
  const [isGridView, setIsGridView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUpdates, setFilteredUpdates] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const [showContainer, setShowContainer] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [shuffledUpdates, setShuffledUpdates] = useState([]);

  const [value, setValue] = useState([12, 19]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClearSearchOnClose = () => {
    if (!isAccordionOpen) {
      setSearchQuery("");
      setIsSearchEmpty(true);
    }
  };
  // Helper function to shuffle an array
  function shuffleArray(array) {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }

  const handleSearch = (event) => {
    const capitalize = (string) => {
      if (!string) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const query = capitalize(event.target.value); // capitalize input
    setSearchQuery(query);

    // Calculate the search count
    const count = filteredUpdates.filter((upd) => {
      return (
        upd.Id.toLowerCase().includes(query.toLowerCase()) || // lowercase the search query to ensure case-insensitivity
        upd.museum.toLowerCase().includes(query.toLowerCase()) ||
        upd.objectName.toLowerCase().includes(query.toLowerCase()) ||
        upd.returned.toLowerCase().includes(query.toLowerCase()) ||
        upd.material.toLowerCase().includes(query.toLowerCase())
      );
    }).length;
    setSearchCount(count);

    setIsSearchEmpty(query === "");
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setSearchCount(0);
    setIsSearchEmpty(true);
  };

  const handleAccordionClose = () => {
    setSearchCount(0);
    setSearchQuery("");
    setFilterInst("All");
    setFilterYear("All");
    setFilterMaterial("All");
    // Call your function to apply filters and update filteredUpdates, if needed.
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const audio = new Audio(hoverSound);
  audio.muted = false;

  const containerRef = useRef(null);

  const handleListView = () => {
    setViewMode("list");
    setIsGridView(true);
  };

  const handleGridView = () => {
    setViewMode("gallery");
    setIsGridView(true);
  };

  const [tokenLinkBase, setTokenLinkBase] = useState(
    "https://opensea.io/assets/matic/0xB22F939f97fC269BCd12a9528217fC6cc20716b7/",
  );

  const getContractAddress = async () => {
    const res = await axios.get(`${baseUrl}/api/v1/data/contractAddress`);
    if (res && res.data && res.data.contractAddress) {
      setTokenLinkBase(
        `https://opensea.io/assets/matic/${res.data.contractAddress}/`,
      );
    }
  };

  useEffect(() => {
    const originalTitle =
      lan && lan === "PG"
        ? "Informate about di Benin Bronzes wey dem don return to Bini — "
        : "Archiving reclaimed Benin Bronzes — Project by Minne — ";
    let titleWithSpaces = `${originalTitle}     `;
    let index = 0;

    const scrollTitle = () => {
      window.document.title =
        titleWithSpaces.substring(index, titleWithSpaces.length) +
        titleWithSpaces.substring(0, index);
      index = (index + 1) % titleWithSpaces.length;
    };

    const intervalId = setInterval(scrollTitle, 400); // Adjust speed as needed

    getContractAddress();
    checkIp();
    fetchData();

    // Cleanup the effect when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [lan]); // Rerun the effect if the language changes

  useEffect(() => {
    handleReplace();
  }, [updates, isOpen]);

  const handleHover = async () => {
    if (isVolumeOn) {
      // const gvolume = setvolume();
      // audio.volume = gvolume ? 1 : 0;
      audio.pause();
      audio.currentTime = 0;
      audio.play();
    }
  };

  useEffect(() => {
    handleReplace();
  }, [viewMode]);

  useEffect(() => {
    let filtered = updates;

    if (filterInst && filterInst !== "All") {
      filtered = filtered.filter((upd) => upd.museum === filterInst);
    }
    if (filterYear && filterYear !== "All") {
      filtered = filtered.filter(
        (upd) => upd.returned.substring(6, 10) === filterYear,
      );
    }
    if (filterMaterial && filterMaterial !== "All") {
      filtered = filtered.filter((upd) => upd.material === filterMaterial);
    }

    // add search filter
    if (searchQuery) {
      filtered = filtered.filter((upd) => {
        const query = searchQuery.toLowerCase();
        return (
          upd.Id.toLowerCase().includes(query) ||
          upd.museum.toLowerCase().includes(query) ||
          upd.objectName.toLowerCase().includes(query) ||
          upd.returned.toLowerCase().includes(query) ||
          upd.material.toLowerCase().includes(query)
        );
      });
    }

    if (value) {
      if (value[0] + value[1] !== 31) {
        filtered = filtered.filter((upd) => {
          return containsNumberInRange(upd.produced, value[0], value[1]);
        });
      }
    }

    if (!areArraysOfObjectsEqual(filtered, filteredUpdates)) {
      setFilteredUpdates(filtered);

      // Shuffle the filtered updates
      const shuffled = shuffleArray(filtered);
      setShuffledUpdates(shuffled);
    }
  }, [updates, filterInst, filterMaterial, filterYear, searchQuery, value]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}></div>
      ) : (
        <div>
          {/* Filter dropdown */}
          <div className={`${classes.rootFilter} mobilefilter`}>
            <Accordion
              TransitionProps={{
                onExited: handleAccordionClose,
              }}
              expanded={isOpen}
              onChange={() => {
                setIsOpen(!isOpen);
                setIsAccordionOpen(!isAccordionOpen);
                handleClearSearchOnClose();
              }}
              style={{ marginTop: "20%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Typography variant="h6">
                  {isOpen && (
                    <div
                      onClick={(e) => {
                        // const gvolume = setvolume();
                        // audio.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audio.play();
                        }
                        e.stopPropagation();
                        setFilterInst("All");
                        setFilterYear("All");
                        setFilterMaterial("All");
                      }}
                    />
                  )}
                  {lan && lan === "PG" ? (
                    <span className="PG  filter-objects">
                      SEARCH DI INFORMATE
                    </span>
                  ) : (
                    <span
                      className="replace"
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioFilter.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioFilter.play();
                        }
                      }}
                    >
                      {isOpen ? (
                        <span className="replace filter-objects">[FILTER]</span>
                      ) : (
                        <span className="replace filter-objects">FILTER</span>
                      )}
                    </span>
                  )}
                </Typography>

                <div
                  className="viewbutton replace"
                  style={{
                    backgroundColor: "black",
                    display: "flex",
                    position: "absolute",
                    right: 0,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Button
                    onClick={handleListView}
                    className="list-button-main replace"
                  >
                    <span
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioFilter.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioFilter.play();
                        }
                      }}
                      className="list-button replace"
                      style={{
                        color: viewMode === "list" ? "white" : "#E0DFD3",
                        marginRight: "10px",

                        transition:
                          "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                      }}
                    >
                      {viewMode === "list" ? "[List]" : "List"}
                    </span>
                  </Button>
                  <Button
                    onClick={handleGridView}
                    className="gallery-button-main"
                  >
                    <span
                      onClick={() => {
                        // const gvolume = setvolume();
                        // audioFilter.volume = gvolume ? 1 : 0;
                        if (isVolumeOn) {
                          audioFilter.play();
                        }
                      }}
                      className="gallery-button replace"
                      style={{
                        color: viewMode === "gallery" ? "white" : "#E0DFD3",
                        transition:
                          "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                      }}
                    >
                      {viewMode === "gallery" ? "[Grid]" : "Grid"}
                    </span>
                  </Button>
                </div>
              </AccordionSummary>
              {/* search bar */}
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  placeholder={
                    lan && lan === "PG"
                      ? "Type di word wey you wan search"
                      : "Enter Your Search"
                  }
                  value={searchQuery}
                  onChange={handleSearch}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className="search-input"
                  style={{
                    transition: "height 0.5s ease",
                    outline: "none",
                    color: isFocused ? "#f8a100" : "#E0DFD3",
                    display: "flex",
                    alignItems: "center",
                    background: "transparent",

                    border: isFocused
                      ? "2px solid #f8a100"
                      : "2px solid #E0DFD3",
                    width: "100%",
                    boxShadow: isFocused ? "inset 0 0 0.6em #f8a100" : "none",
                    WebkitInputPlaceholder: isFocused ? "#f8a100" : "#E0DFD3",
                  }}
                />
                {searchQuery && (
                  <InputAdornment
                    position="end"
                    style={{ position: "absolute", right: 0 }}
                  >
                    <IconButton
                      onClick={handleClearSearch}
                      style={{
                        transform: "scale(3)",
                        paddingRight: "20px",
                        color: "#f8a100!important",
                      }}
                    >
                      <ClearIcon style={{ color: "#f8a100!important" }} />
                    </IconButton>
                  </InputAdornment>
                )}
              </div>
              {!isSearchEmpty && (
                <div
                  className="searchcount replace"
                  handleReplace={handleReplace}
                  style={{ color: "#E0DFD3" }}
                >
                  {lan && lan === "PG" ? (
                    <>
                      <span className="boldwords"> {searchCount} </span>
                      tins dey show for
                      <span className="boldwords"> {searchQuery} </span>
                    </>
                  ) : (
                    <>
                      <span className="boldwords"> {searchCount} </span>
                      results found for
                      <span className="boldwords"> {searchQuery} </span>
                    </>
                  )}
                </div>
              )}
              {/* ends here  */}
              <AccordionDetails>
                {/* add search box */}

                {/* Filters */}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>
                    {lan && lan === "PG" ? (
                      <span className="PG pidginfilter replace">
                        Which museum hold di Benin Bronze
                        <span className="replace">?</span>
                      </span>
                    ) : (
                      <span>Institution</span>
                    )}
                  </InputLabel>
                  <Select
                    value={filterInst}
                    onChange={(e) => setFilterInst(e.target.value)}
                    label="Institution"
                    renderValue={(selected) =>
                      lan === "PG" && selected === "All" ? "" : selected
                    }
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {institutions.map((inst) => {
                      return <MenuItem value={inst}>{inst}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>
                    {lan && lan === "PG" ? (
                      <span
                        className="PG pidginfilter replace"
                        style={{ paddingBottom: "20px" }}
                      >
                        Which year dem return di Benin Bronze
                        <span className="replace">?</span>
                      </span>
                    ) : (
                      <span>Return Year</span>
                    )}
                  </InputLabel>
                  <Select
                    value={filterYear}
                    onChange={(e) => setFilterYear(e.target.value)}
                    label="Return Year"
                    renderValue={(selected) =>
                      lan === "PG" && selected === "All" ? "" : selected
                    }
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {years.map((year) => {
                      return <MenuItem value={year}>{year}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>
                    {lan && lan === "PG" ? (
                      <span className="PG pidginfilter replace">
                        Wetin dem use make di Benin Bronze
                        <span className="replace">?</span>
                      </span>
                    ) : (
                      <span>Material Composition</span>
                    )}
                  </InputLabel>
                  <Select
                    value={filterMaterial}
                    onChange={(e) => setFilterMaterial(e.target.value)}
                    label="Material Composition"
                    renderValue={(selected) =>
                      lan === "PG" && selected === "All" ? "" : selected
                    }
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {materials.map((material) => {
                      return <MenuItem value={material}>{material}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </AccordionDetails>{" "}
              <div
                className="year--of--prod replace"
                style={{ boxSizing: "border-box" }}
              >
                <h2>
                  <span className="replace">
                    {lan && lan === "PG"
                      ? "Century wey dem make di Benin Bronze:"
                      : "Year of Production:"}{" "}
                  </span>
                  {value[0] <= 12 ? "12th" : `${value[0]}th`}
                  {value[0] !== value[1]
                    ? ` - ${value[1] >= 19 ? "19th" : `${value[1]}th`}`
                    : ""}{" "}
                  {value[0] !== value[1] ? "Centuries" : "Century"}
                </h2>

                <Slider
                  value={value}
                  onChange={handleChange}
                  aria-labelledby="discrete-slider-always"
                  getAriaValueText={valuetext}
                  min={11}
                  max={20}
                  step={1}
                  marks={marks.map((mark) => ({
                    ...mark,
                    label: (
                      <span
                        className={
                          mark.value >= value[0] && mark.value <= value[1]
                            ? "special-font-class"
                            : ""
                        }
                      >
                        {mark.label}
                      </span>
                    ),
                  }))}
                />
              </div>
            </Accordion>
          </div>

          <div className="mobile--width" style={{ margin: "1vw 2vw" }}>
            {filterInst &&
            filterYear &&
            filterMaterial &&
            value &&
            !(
              filterInst === "All" &&
              filterMaterial === "All" &&
              filterYear === "All" &&
              searchQuery === "" &&
              value[0] + value[1] === 31
            ) ? (
              lan && lan === "PG" ? (
                <Typography style={{ color: "#808080" }} variant="">
                  E dey show{" "}
                  <span className="boldwords">
                    {filteredUpdates && filteredUpdates.length}
                  </span>{" "}
                  from{" "}
                  <span className="boldwords">{updates && updates.length}</span>{" "}
                  Benin Bronzes
                </Typography>
              ) : (
                <Typography style={{ color: "#808080" }} variant="">
                  Showing{" "}
                  <span className="boldwords">
                    {filteredUpdates && filteredUpdates.length}
                  </span>{" "}
                  out of{" "}
                  <span className="boldwords">
                    {" "}
                    {updates && updates.length} Benin Bronzes
                  </span>
                </Typography>
              )
            ) : null}

            <div
              className={`container ${
                viewMode === "list" ? "list-container" : "gallery-container"
              } fade-upward ${showContainer ? "show" : ""} mobile-width`}
            >
              {viewMode === "gallery" ? (
                <div
                  ref={containerRef}
                  className={`${
                    viewMode === "gallery"
                      ? "gallery-container"
                      : "list-container"
                  }`}
                >
                  <Grid container spacing={2}>
                    {" "}
                    {/* <-- add this container */}
                    {shuffledUpdates.map((upd, index) => (
                      <Grid item xs={6} key={index}>
                        {" "}
                        {/* <-- set item to take half of the row */}
                        <div
                          className="gallery-item mobile-width"
                          onMouseEnter={() => handleHover()}
                          onClick={() =>
                            window.open(
                              `${tokenLinkBase}${upd.tokenNum.slice(2)}`,
                              "_blank",
                            )
                          }
                        >
                          <h6
                            className="objectid"
                            style={{ display: "block!important" }}
                          >
                            {upd.Id}{" "}
                          </h6>
                          <h3>
                            <span className="museumtitle font-transition">
                              INSTITUTION{" "}
                            </span>
                            <span className="museumcontent replace">
                              {upd.museum}
                            </span>
                          </h3>
                          <h3>
                            <span className="objecttitle font-transition">
                              {" "}
                              OBJECT{" "}
                            </span>
                            <span className="objectcontent font-transition">
                              {upd.objectName}
                            </span>
                          </h3>
                          <h3>
                            <span className="returntitle font-transition">
                              RETURN DATE
                            </span>
                            <span className="returncontent font-transition">
                              {" "}
                              {upd.returned}{" "}
                            </span>
                          </h3>
                          {notes[upd.tokenNum] && (
                            <>
                              <div
                                style={{
                                  fontFamily: "symbols",
                                  lineHeight: "0.4",
                                  marginTop: "50px",
                                }}
                              >
                                <span
                                  className="notetitle replace"
                                  style={{
                                    fontFamily: "symbols",
                                    lineHeight: "0.4",
                                  }}
                                >
                                  {lan && lan === "PG" ? (
                                    <span className="replace">
                                      EXTRA INFORMATE -
                                    </span>
                                  ) : (
                                    <span className="replace">NOTE —</span>
                                  )}
                                </span>
                                <span className="notecontent replace">
                                  {" "}
                                  {notes[upd.tokenNum]}{" "}
                                </span>
                              </div>
                            </>
                          )}
                          {instituteNotes[upd.museum.toLowerCase()] && (
                            <>
                              <div
                                style={{
                                  fontFamily: "symbols",
                                  lineHeight: "0.4",
                                  marginTop: "50px",
                                }}
                              >
                                <span
                                  className="notetitle replace"
                                  style={{
                                    fontFamily: "symbols",
                                    lineHeight: "0.4",
                                  }}
                                >
                                  INSTITUTE NOTE —
                                </span>
                                <span className="notecontent replace">
                                  {" "}
                                  {
                                    instituteNotes[upd.museum.toLowerCase()]
                                  }{" "}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ) : (
                // Display list view
                <div
                  ref={containerRef}
                  className={`${
                    viewMode === "list" ? "list-container" : "gallery-container"
                  }mobile-width`}
                >
                  {shuffledUpdates.map((upd, index) => (
                    <div
                      key={index}
                      className="list-item"
                      onMouseEnter={() => handleHover()}
                      // Update onClick function to open the link in a new tab
                      onClick={() =>
                        window.open(
                          `${tokenLinkBase}${upd.tokenNum.slice(2)}`,
                          "_blank",
                        )
                      }
                    >
                      <div
                        className="objectid"
                        style={{ display: "inline-block" }}
                      >
                        {upd.Id}{" "}
                      </div>
                      <div>
                        <span className="museumtitle ">INSTITUTION </span>
                        <span className="museumcontent replace">
                          {upd.museum}
                        </span>
                      </div>
                      <div>
                        <span className="objecttitle "> OBJECT </span>
                        <span className="objectcontent ">
                          {upd.objectName}
                        </span>{" "}
                      </div>
                      <div>
                        <span className="returntitle ">RETURN DATE</span>
                        <span className="returncontent "> {upd.returned} </span>
                      </div>
                      {notes[upd.tokenNum] && (
                        <>
                          <div
                            style={{
                              fontFamily: "symbols",
                              lineHeight: "0.4",
                              marginTop: "50px",
                            }}
                          >
                            <span
                              className="notetitle replace"
                              style={{
                                fontFamily: "symbols",
                                lineHeight: "0.4",
                              }}
                            >
                              {" "}
                              {lan && lan === "PG" ? (
                                <span className="replace">
                                  EXTRA INFORMATE -
                                </span>
                              ) : (
                                <span className="replace">NOTE —</span>
                              )}
                            </span>
                            <span className="notecontent ">
                              {" "}
                              {notes[upd.tokenNum]}{" "}
                            </span>
                          </div>
                        </>
                      )}
                      {instituteNotes[upd.museum.toLowerCase()] && (
                        <>
                          <div
                            style={{
                              fontFamily: "symbols",
                              lineHeight: "0.4",
                              marginTop: "50px",
                            }}
                          >
                            <span
                              className="notetitle replace"
                              style={{
                                fontFamily: "symbols",
                                lineHeight: "0.4",
                              }}
                            >
                              INSTITUTE NOTE —
                            </span>
                            <span className="notecontent ">
                              {" "}
                              {instituteNotes[upd.museum.toLowerCase()]}{" "}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              className={`container ${
                viewMode === "gallery" ? "gallery-container" : "list-container"
              }`}
            ></div>
          </div>
        </div>
      )}
      <ScrollTopButton lan={lan} isVolumeOn={isVolumeOn} />
    </>
  );
}

export default Update;

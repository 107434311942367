import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
import { ThemeProvider } from "./themeContext";
import "./global-styles.css";

const TRACKING_ID = "G-3T0LGKH403";
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider>
        <App />{" "}
      </ThemeProvider>
      {/* <div className="App">
        <div
          data-widget="m-connect"
          data-delay-auth="true"
          data-network={97}
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        ></div>
        <div
          data-widget="m-layout-complete-listing"
          data-id={2}
          data-network={97}
        />
      </div> */}
    </Router>
  </React.StrictMode>
);
reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import React from "react";
import { Zoom, useScrollTrigger, makeStyles, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { animateScroll as scroll } from "react-scroll";
import soundFile from "../sounds/backtotop.mp3";

function ScrollTop(props) {
  const {
    children,
    trigger,
    classes,
    handleReplace,
    fetchData,
    lan,
    isVolumeOn,
  } = props;
  const audio = new Audio(soundFile);

  const handleClick = (event) => {
    if (isVolumeOn) {
      audio.play();
    }
    scroll.scrollToTop({ behavior: "smooth" });
    if (handleReplace && fetchData && lan) {
      handleReplace("en", lan, fetchData);
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.scrollButton}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  trigger: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleReplace: PropTypes.func,
  fetchData: PropTypes.func,
  lan: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  scrollButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    color: "black",
    background: "rgb(43, 45, 60)",
    zIndex: "111111",
  },
  buttonText: {
    "&:hover": {
      color: "#f8a100",
    },
  },
}));

function ScrollTopButton({ handleReplace, fetchData, lan, isVolumeOn }) {
  const [trigger, setTrigger] = React.useState(false);
  const classes = useStyles();

  const handleScroll = () => {
    const currentPosition = window.pageYOffset;

    if (currentPosition > 300) {
      setTrigger(true);
    } else {
      setTrigger(false);
    }
  };

  const triggerScroll = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ScrollTop
      trigger={trigger || triggerScroll}
      classes={classes}
      handleReplace={handleReplace}
      fetchData={fetchData}
      isVolumeOn={isVolumeOn}
      lan={lan}
    >
      <Button
        aria-label="scroll back to top"
        className="color-border scrolltotop"
        style={{
          background: "rgb(43, 45, 60)",
          ":hover": { backgroundColor: "red" },
        }}
      >
        <span
          className={`buttonText ${lan && lan === "PG" ? "goUp" : "backToTop"}`}
        >
          {lan && lan === "PG" ? "GO UP" : "BACK TO TOP"}
        </span>
      </Button>
    </ScrollTop>
  );
}

export default ScrollTopButton;

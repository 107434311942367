import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Update from "./Components/Updates";
import NavBar from "./Components/Navbar";
import About from "./Components/About";
import Info from "./Components/Info";
import Popup from "./Components/Popup";
import Ebay from "./Components/Ebay";
import ProductDetail from "./Components/Ebay/ProductDetail";
import ReactGA from "react-ga";
import moment from "moment";
import marqueeSound from "./sounds/backtotop.mp3";
import FilterSound from "./sounds/menu.mp3";
import CloseSound from "./sounds/closed.mp3";
import { Howl } from "howler";

import { useEffect, useState, useRef } from "react";
import axios from "axios";

let baseUrl = "";
baseUrl = "https://beninbronzes.xyz";

function getTimeElapsed(startTime) {
  const rightNow = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss");
  const total = Date.parse(rightNow) - Date.parse(startTime);
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

function App() {
  const toggleLightMode = () => {
    const newMode = !document.documentElement.classList.toggle("light-mode");
    return newMode;
  };

  const audioRef = useRef(new Howl({ src: [marqueeSound] }));
  const audioFilter = new Howl({ src: [FilterSound] });
  const audioClose = new Howl({ src: [CloseSound] });
  const [isVolumeOn, setIsVolumeOn] = useState(true);

  // const handleToggle = () => {
  //   const newIsVolumeOn = !isVolumeOn;
  //   setIsVolumeOn(newIsVolumeOn);
  //   // set volume of audio elements accordingly
  // };
  const handleToggle = () => {
    const newIsVolumeOn = !isVolumeOn;
    setIsVolumeOn(newIsVolumeOn);
    // audioFilter.volume(newIsVolumeOn ? 1 : 0);
    // audioClose.volume(newIsVolumeOn ? 1 : 0);
    // audioRef.current.volume(newIsVolumeOn ? 1 : 0);
  };

  const [num, setNum] = useState(0);
  const [lastUpdate, setLastUpdate] = useState("");
  const [lastMint, setLastMint] = useState("");
  const [hasAccess, setHasAccess] = useState(null);
  const [counts, setCounts] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [institutions2, setInstitutions2] = useState([]);
  const [years, setYears] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [lan, setLan] = useState("EN");
  const [iphasAccess, setIpHasAccess] = useState(false);
  const [data2, setData2] = useState([]);
  const [lightMode, setLightMode] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false); // store user's theme selection
  const [matD, setMatD] = useState(null);
  const [rDates, setRDates] = useState({});
  const [headCounts, setHeadCounts] = useState(0);
  const [headItems, setHeadItems] = useState([]);
  const [mostRecentRDate, setMostRecentRDate] = useState(null);

  useEffect(() => {
    // Function to apply correct theme
    const applyTheme = () => {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;
      if (isMobile && !isDarkMode) {
        document.documentElement.classList.add("light-mode");
        document.documentElement.classList.remove("dark-mode");
      } else if (!isMobile && !isDarkMode) {
        document.documentElement.classList.remove("light-mode");
        document.documentElement.classList.add("dark-mode");
      } else if (isDarkMode) {
        document.documentElement.classList.remove("light-mode");
        document.documentElement.classList.add("dark-mode");
      } else {
        document.documentElement.classList.remove("light-mode");
        document.documentElement.classList.remove("dark-mode");
      }
    };

    // Apply the theme initially when the component mounts
    applyTheme();

    // Apply the theme again whenever isDarkMode changes
  }, [isDarkMode]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseUrl}/api/v1/data`);
      const res2 = await axios.get(`${baseUrl}/api/v1/data/data`);
      setMatD(res2.data);

      const institutionsSet = new Set(res.data.map((item) => item.museum));
      setInstitutions(Array.from(institutionsSet));

      // Declare an object to hold each institution and its count.
      const institutionCounts = {};
      let rDatesT = {};
      res.data.forEach((item) => {
        institutionCounts[item.museum] = institutionCounts[item.museum]
          ? institutionCounts[item.museum] + 1
          : 1;
        rDatesT[item.museum.toLowerCase()] = item.returned;
      });
      console.log(rDatesT);
      setRDates(rDatesT);

      const allRDates = Object.values(rDatesT);
      let recentRDate;
      if (allRDates.length > 0) {
        recentRDate = allRDates.sort()[allRDates.length - 1];
        setMostRecentRDate(recentRDate);
      }

      console.log(`The most recent rDate is: ${mostRecentRDate}`);

      // Convert the object to an array of arrays for easier use.
      const institutionsArray = Object.entries(institutionCounts);
      // institutionsArray is an array of arrays like [["Institution1", count1], ["Institution2", count2], ...]
      setInstitutions2(institutionsArray);

      const yearsSet = new Set(
        res.data.map((item) => item.returned.substring(6, 10)),
      );
      setYears(Array.from(yearsSet));
      console.log(Array.from(yearsSet));
      const materialTemp = res.data.map((item) => item.material);
      const countHeads = res.data.filter((item) => {
        return item.objectName.toLowerCase().indexOf("head") !== -1;
      });
      setHeadCounts(countHeads.length);
      setHeadItems(countHeads);

      let counts = {};
      let tempD = [];
      for (let m of materialTemp) {
        counts[m] = counts[m] ? counts[m] + 1 : 1;
      }
      let tempC = Object.keys(counts).map((key) => [key, counts[key]]);
      for (let m of tempC) {
        tempD.push({
          name: m[0].toUpperCase(),
          amt: Math.round((m[1] / res.data.length) * 100),
        });
      }
      let tt = res.data;
      tt.sort((a, b) => {
        return new Date(a.lastUpdates) - new Date(b.lastUpdated);
      });
      if (tt.length > 0) {
        let lU = moment(tt[tt.length - 1].lastUpdated * 1000)
          .utc()
          .format("MMMM DD, YYYY HH:mm A");
        setLastUpdate(`${lU} GMT`);
      }

      let tt2 = res.data;
      tt2.sort((a, b) => {
        return new Date(a.recorded) - new Date(b.recorded);
      });
      if (tt.length > 0) {
        let lU2 = moment(new Date(tt2[tt2.length - 1].recorded))
          .utc()
          .format("MMMM DD, YYYY");
        setLastMint(lU2);
      }
      setData2(tempD);

      setCounts(tempC);
      const materialsSet = new Set(materialTemp);
      setMaterials(Array.from(materialsSet));

      setUpdates(res.data.reverse());
      setNum(res.data && res.data.length);

      setLoading(false);
    } catch (err) {
      window.alert(err.message);
    }
  };

  const checkIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    const resS = await axios.get(`${baseUrl}/api/v1/ip`);
    const myIp = res.data.IPv4;
    const allowedIps = resS.data.addresses;
    if (allowedIps.indexOf(myIp) !== -1) {
      setIpHasAccess(true);
    }
  };

  const handleReplace = async () => {
    if (num) {
      // Total mints
      let els = document.getElementsByClassName("totalMints");

      if (els) {
        for (let el of els) {
          el.innerHTML = num;
        }
      }
    }

    if (lastUpdate) {
      let els = document.getElementsByClassName("lastUpdate");

      if (els) {
        for (let el of els) {
          el.innerHTML = lastUpdate;
        }
      }
    }

    if (lastMint) {
      let els = document.getElementsByClassName("lastMint");

      if (els) {
        for (let el of els) {
          el.innerHTML = lastMint;
        }
      }
    }

    if (headCounts) {
      let els = document.getElementsByClassName("headCounts");

      if (els) {
        for (let el of els) {
          el.innerHTML = headCounts;
        }
      }
    }

    let els = document.getElementsByClassName("replace");
    let els2 = document.getElementsByClassName("recharts-text");
    els = [...els, ...els2];

    if (els) {
      for (let el of els) {
        let temp = el.innerHTML;
        if (temp.indexOf("span") === -1) {
          temp = temp.replace(
            /[/]/g,
            `<span style="font-family:Symbols;">/</span>`,
          );
          temp = temp.replace(
            /"(?!font|>)/g,
            `<span style="font-family:Symbols;">"</span>`,
          );
          temp = temp.replace(
            /[']/g,
            `<span style="font-family:Symbols;">'</span>`,
          );
          temp = temp.replace(
            /[’]/g,
            `<span style="font-family:Symbols;">’</span>`,
          );
          temp = temp.replace(
            /[‘]/g,
            `<span style="font-family:Symbols;">‘</span>`,
          );
          temp = temp.replace(
            /[“]/g,
            `<span style="font-family:Symbols;">“</span>`,
          );
          temp = temp.replace(
            /[”]/g,
            `<span style="font-family:Symbols;">”</span>`,
          );
          temp = temp.replace(
            /[—]/g,
            `<span style="font-family:Symbols;">—</span>`,
          );
          temp = temp.replace(
            /:(?!Symbols)/g,
            `<span style="font-family:Symbols;">:</span>`,
          );
          temp = temp.replace(
            /[+]/g,
            `<span style="font-family:Symbols;">+</span>`,
          );
          temp = temp.replace(
            /[[]/g,
            `<span style="font-family:Symbols;">[</span>`,
          );
          temp = temp.replace(
            /[\]]/g,
            `<span style="font-family:Symbols;">]</span>`,
          );
          temp = temp.replace(
            /[(]/g,
            `<span style="font-family:Symbols;">(</span>`,
          );
          temp = temp.replace(
            /[)]/g,
            `<span style="font-family:Symbols;">)</span>`,
          );
          temp = temp.replace(
            /[{]/g,
            `<span style="font-family:Symbols;">{</span>`,
          );
          temp = temp.replace(
            /[}]/g,
            `<span style="font-family:Symbols;">}</span>`,
          );
          temp = temp.replace(
            /[?]/g,
            `<span style="font-family:Symbols;">?</span>`,
          );
          temp = temp.replace(
            /[@]/g,
            `<span style="font-family:Symbols;">@</span>`,
          );
          temp = temp.replace(
            /[']/g,
            `<span style="font-family:Symbols;">'</span>`,
          );
          temp = temp.replace(
            /[%]/g,
            `<span style="font-family:Symbols;">%</span>`,
          );
          el.innerHTML = temp;
        }
      }
    }
  };

  useEffect(() => {
    const acc = localStorage.getItem("access");
    if (acc) setHasAccess(true);
    else setHasAccess(false);
    if (window.location.href.indexOf("pidgin") !== -1) {
      setLan("PG");
    } else {
      setLan("EN");
    }
  }, []);

  useEffect(() => {
    handleReplace();
  }, [updates, lastMint, lastUpdate]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Route path="/igun">
            {hasAccess ? (
              <>
                <NavBar
                  num={num}
                  counts={counts}
                  lan={lan}
                  handleReplace={handleReplace}
                  lastUpdate={lastUpdate}
                  toggleLightMode={toggleLightMode}
                  lightMode={lightMode}
                  updates={updates}
                  handleToggle={handleToggle}
                  isVolumeOn={isVolumeOn}
                  audioFilter={audioFilter}
                  audioClose={audioClose}
                  matD={matD}
                />
                <About
                  handleReplace={handleReplace}
                  fetchData={fetchData}
                  lan={lan}
                  myData={data2}
                  updates={updates}
                  materials={materials}
                  institutions={institutions}
                  isVolumeOn={isVolumeOn}
                  audioBook={audioRef}
                  audioClose={audioClose}
                  audioFilter={audioFilter}
                  baseUrl={baseUrl}
                />{" "}
              </>
            ) : hasAccess === false ? (
              <Popup
                setHasAccess={setHasAccess}
                lan={lan}
                handleReplace={handleReplace}
              />
            ) : (
              <></>
            )}
          </Route>
          <Route path="/research">
            {hasAccess ? (
              <>
                <NavBar
                  num={num}
                  counts={counts}
                  lan={lan}
                  handleReplace={handleReplace}
                  lastUpdate={lastUpdate}
                  toggleLightMode={toggleLightMode}
                  lightMode={lightMode}
                  updates={updates}
                  handleToggle={handleToggle}
                  isVolumeOn={isVolumeOn}
                  audioFilter={audioFilter}
                  audioClose={audioClose}
                  matD={matD}
                />
                <Info
                  handleReplace={handleReplace}
                  fetchData={fetchData}
                  lan={lan}
                  baseUrl={baseUrl}
                  myData={data2}
                  updates={updates}
                  materials={materials}
                  institutions={institutions2}
                  isVolumeOn={isVolumeOn}
                  rDates={rDates}
                  date={mostRecentRDate}
                />
              </>
            ) : hasAccess === false ? (
              <Popup
                setHasAccess={setHasAccess}
                lan={lan}
                handleReplace={handleReplace}
              />
            ) : (
              <></>
            )}
          </Route>
          <Route path="/no_access">
            {lan && lan === "PG" ? (
              <div className="noAccess PG">
                You don decide say you no go enta dis website.
              </div>
            ) : (
              <div className="noAccess">
                You have opted out of visiting this website.
              </div>
            )}
          </Route>
          <Route path="/ebay" component={Ebay} />
          <Route path="/product/:id" component={ProductDetail} />
          <Route path="*">
            {hasAccess ? (
              <>
                <NavBar
                  num={num}
                  counts={counts}
                  lan={lan}
                  handleReplace={handleReplace}
                  lastUpdate={lastUpdate}
                  toggleLightMode={toggleLightMode}
                  lightMode={lightMode}
                  updates={updates}
                  handleToggle={handleToggle}
                  isVolumeOn={isVolumeOn}
                  audioFilter={audioFilter}
                  audioClose={audioClose}
                  matD={matD}
                  items={headItems}
                />
                <Update
                  handleReplace={handleReplace}
                  fetchData={fetchData}
                  checkIp={checkIp}
                  updates={updates}
                  loading={loading}
                  institutions={institutions}
                  materials={materials}
                  years={years}
                  lan={lan}
                  baseUrl={baseUrl}
                  isVolumeOn={isVolumeOn}
                  audioFilter={audioFilter}
                />
              </>
            ) : hasAccess === false ? (
              <Popup
                setHasAccess={setHasAccess}
                lan={lan}
                handleReplace={handleReplace}
              />
            ) : (
              <></>
            )}
          </Route>
        </Switch>
      </Router>
      {/* <Museum /> */}
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./styles";
import "../App.css";
import Popup from "reactjs-popup";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import ReactGA from "react-ga";
import { useTheme } from "../themeContext";

// const collectionLink = 'https://testnets.opensea.io/collection/dynamicnft-jfsbaqs0wv';

import { downloadCSV } from "./helper";

const useStylesBootstrap = makeStyles((theme) => ({
  tooltipLight: {
    backgroundColor: "#000000",
    fontFamily: "LightNarrow",
    fontSize: "2rem",
    border: "1px dashed #f8a100",
    color: "#f8a100!important",
    padding: "20px",
    borderRadius: "0",
    lineHeight: "1",
    boxShadow: "inset 0 0 1em #f8a100, 0 0 5em #f8a100",  zIndex:"99999"
  },
  tooltipDark: {
    backgroundColor: "#cf3031",
    fontSize: "2rem",
    fontFamily: "LightNarrow",
    border: "2px solid",
    borderRadius: "0",
    lineHeight: "1",
    color: "#e0dfd3",
    boxShadow: "inset 0 0 1em #f8a100, 0 0 5em #f8a100",
    padding: "20px",
   zIndex:"99999"
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  const isLightMode = document.documentElement.classList.contains("light-mode");

  const tooltipClass = isLightMode ? classes.tooltipLight : classes.tooltipDark;

  return (
    <Tooltip 
      classes={{ tooltip: clsx(tooltipClass, 'mobile-hide-tooltip') }} 
      PopperProps={{ style: { zIndex: 9999 } }} 
      {...props} 
    />
  );
  }


// const audioFilter = new Howl({ src: [FilterSound] });
// const audioClose = new Howl({ src: [CloseSound] });

// const audio = new Howl({ src: [buttonSound] });
let gvolume = true;
function Header({
  num,
  counts,
  lan,
  fontStyles,
  handleReplace,
  lastUpdate,
  lightMode,
  toggleLightMode,
  updates,
  handleToggle,
  isVolumeOn,
  audioFilter,
  audioClose,
  matD,
}) {
  let history = useHistory();
  let location = useLocation();

  const [path, setPath] = useState("");

  // const audioRef = useRef(new Howl({ src: [marqueeSound] }));

  const classes = useStyles();
  const [isLightMode, setIsLightMode] = useState(false);

  const [volumeD, setVolumeD] = useState([]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useEffect(() => {
    handleReplace();
  }, [path, lan]);

  useEffect(() => {
    if (matD && matD.materials && matD.materialsPrev) {
      const { materials, materialsPrev } = matD;
  
      const totalQuantityPrev = materialsPrev.reduce((total, material) => total + material.quantity, 0);
      const totalQuantity = materials.reduce((total, material) => total + material.quantity, 0);
  
      const volumeChangeData = materials.map((material) => {
        const prevMaterial = materialsPrev.find((m) => m.name === material.name);
        const prevQuantity = prevMaterial ? prevMaterial.quantity : 0;
  
        const presVolume = (totalQuantity !== 0) ? material.quantity / totalQuantity : 0;
        const prevVolume = (totalQuantityPrev !== 0) ? prevQuantity / totalQuantityPrev : 0;
  
        const volumeChange = (presVolume - prevVolume) * 100;
  
        return {
          name: material.name,
          volume: presVolume * 100,
          volumeChange: volumeChange,
        };
      });
  
      setVolumeD(volumeChangeData);
    }
  }, [matD]);
  

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handleNavigate = async (path) => {
    if (isVolumeOn) {
      audioFilter.pause();
      audioFilter.currentTime = 0;
      audioFilter.play();
    }
    history.push(path);
  };
  // const [isVolumeOn, setIsVolumeOn] = useState(true);

  const handleDownload = async () => {
    downloadCSV(updates);
  };

  const { theme } = useTheme();

  return (
    <div style={{ marginBottom: "10vh" }}>
      <CssBaseline />

      <AppBar
        position="absolute"
        className={clsx(classes.appBar, true && classes.appBarShift)}
        id="back-to-top-anchor"
      >
        <Toolbar
          className={`${classes.toolbar} ${
            theme === "light" ? "light-mode" : "dark-mode"
          }`}
        >
          {/* <marquee width='100%' direction='left'> */}
          <div className="marquee-container">
            <div className="marquee">
              <span>
                <span>
                  <Typography
                    color="inherit"
                    noWrap
                    className={classes.title}
                    style={{
                      fontSize: "100px",
                      ...fontStyles,
                      fontFamily: "BoldItalicNarrow",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      BENIN BRONZES {num}
                    </span>
                    {counts &&
                      counts.map((mat) => (
                        <>
                          {mat[0].toUpperCase()}{" "}
                          {Math.round((mat[1] / num) * 100)}
                          <div
                            style={{
                              display: "inline-block",
                              fontFamily: "BoldItalicNarrow",
                              marginRight: "50px",
                            }}
                          >
                            %
                          </div>
                        </>
                      ))}
                  </Typography>
                </span>
                <span>
                  <Typography
                    color="inherit"
                    noWrap
                    className={classes.title}
                    style={{
                      fontSize: "100px",
                      ...fontStyles,
                      fontFamily: "BoldItalicNarrow",
                    }}
                  >
                    BENIN BRONZES {num}
                    {counts &&
                      counts.map((mat) => (
                        <>
                          {" "}
                          {mat[0].toUpperCase()}{" "}
                          {Math.round((mat[1] / num) * 100)}
                          <div
                            style={{
                              display: "inline-block",
                              fontFamily: "BoldItalicNarrow",
                            }}
                          >
                            %
                          </div>
                        </>
                      ))}
                  </Typography>
                </span>
              </span>
            </div>
          </div>
          <div
            className="researchnav replace"
            style={{
              display: "flex",
              position: "absolute",
              top: 0,
              right: 0,
              ...fontStyles,
            }}
          >
            <Button
              onClick={() => handleNavigate("/")}
              variant="contained"
              className={clsx(
                classes.navLink,
                path === "/" && classes.active,
                "replace"
              )}
              style={{
                transition: "all 0.2s ease-in-out",
                transform: "translateY(0)",
                ...fontStyles,
              }}
            >
              {lan && lan === "PG" ? (
                path === "/" ? (
                  <span className="replace">[Domot]</span>
                ) : (
                  <span className="replace">Domot</span>
                )
              ) : path === "/" ? (
                <span className="replace">[Home]</span>
              ) : (
                <span className="replace">Home</span>
              )}
            </Button>

             
            {lan !== "PG" && (
  <Button
    onClick={() => handleNavigate("/igun")}
    variant="contained"
    className={clsx(
      classes.navLink,
      path === "/igun" && classes.active,
      "replace"
    )}
    style={{ transition: "all 0.2s ease-in-out", ...fontStyles }}
  >
    {path === "/igun" ? (
      <span className="replace">[IGUN AI]</span>
    ) : (
      <span className="replace">IGUN AI</span>
    )}
  </Button>
)}
                   

            <Button
              onClick={() => handleNavigate("/research")}
              variant="contained"
              className={clsx(
                classes.navLink,
                path === "/research" && classes.active,
                "replace"
              )}
              style={{ transition: "all 0.2s ease-in-out", ...fontStyles }}
            >
              {lan && lan === "PG" ? (
                path === "/research" ? (
                  <span className="replace">[Wetin be Dis?]</span>
                ) : (
                  <span className="replace">Wetin be Dis?</span>
                )
              ) : path === "/research" ? (
                <span className="replace">[Research Notes]</span>
              ) : (
                <span className="replace">Research Notes</span>
              )}
            </Button>
            <Button
              onClick={() => handleDownload()}
              variant="contained"
              className={clsx(classes.navLink, "download")}
              style={{
                Contactransition: "all 0.2s ease-in-out",
                ...fontStyles,
              }}
            >
              <BootstrapTooltip
                handleReplace={handleReplace}
                lan={lan}
                className="download-records"
                title={
                  lan && lan === "PG" ? (
                    <>
                      {"Dis link dey jinja di"}
                      <span className="tooltip-alt"> getAllTokenUpdates</span>
                      {
                        " command for di smart contract wey go download di full list of Benin Bronzes."
                      }
                   
                    </>
                  ) : (
                    <>
                      {"This link executes the "}
                      <span className="tooltip-alt">getAllTokenUpdates</span>
                      {
                        " command on the smart contract, then initiates a CSV download of the latest on-chain records."
                      }
                     
                    </>
                  )
                }
              >
                <span>
                  {lan && lan === "PG"
                    ? path === "/contact"
                      ? "[Download di data]"
                      : "Download di data"
                    : path === "/contact"
                    ? "[Download data]"
                    : "Download data"}
                </span>
              </BootstrapTooltip>
            </Button>
            <Button
              onClick={() =>
                (window.location.href = "mailto:return@beninbronzes.xyz")
              }
              variant="contained"
              className={clsx(
                classes.navLink,
                path === "/contact" && classes.active,
                "replace contact--email"
              )}
              style={{
                Contactransition: "all 0.2s ease-in-out",
                ...fontStyles,
              }}
            >
              {lan && lan === "PG"
                ? path === "/contact"
                  ? "[Send Email]"
                  : "Send Email"
                : path === "/contact"
                ? "[Contact]"
                : "Contact"}
            </Button>
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <div className="languagelinks replace">
                {/* Language */}
                <BootstrapTooltip
                  handleReplace={handleReplace}
                  lan={lan}
                  title={lan && lan === "PG" ? "Yanki English" : ""}
                >
                  <Typography
                    variant="h6"
                    style={{
                      cursor: "pointer",
                      textDecoration:
                        lan && lan === "PG" ? "none" : "underline",
                      marginRight: "20px",
                      background: "none",
                      transition: "all 0.2s ease-in-out",
                      ...fontStyles,
                    }}
                    // className={path === "/" && lan !== "PG" ? classes.active : ""}
                    onClick={() =>
                      lan &&
                      lan === "PG" &&
                      (window.location.href = "http://beninbronzes.xyz/")
                    }
                  >
                    {
                      <>
                        {lan === "EN" ? (
                          <span className="replace englang">[EN]</span>
                        ) : (
                          <span className="englang replace">EN</span>
                        )}
                      </>
                    }
                  </Typography>
                </BootstrapTooltip>
                <BootstrapTooltip
                  handleReplace={handleReplace}
                  lan={lan}
                  title={
                    lan && lan === "PG" ? (
                      "Naija Pidjin"
                    ) : (
                      <>{"Nigerian Pidgin English"}</>
                    )
                  }
                >
                  <Typography
                    variant="h6"
                    style={{
                      cursor: "pointer",
                      textDecoration:
                        lan && lan === "PG" ? "underline" : "none",
                      marginRight: "20px",
                      background: "none",
                      transition: "all 0.2s ease-in-out",
                      ...fontStyles,
                    }}
                    onClick={() =>
                      lan &&
                      lan !== "PG" &&
                      (window.location.href = "http://pidgin.beninbronzes.xyz/")
                    }
                  >
                    {path === "/" ? (
                      <>
                        {lan === "PG" ? (
                          <span className="replace pidlang">[PG]</span>
                        ) : (
                          <span className="pidlang replace">PG</span>
                        )}
                      </>
                    ) : (
                      <>
                        {lan === "PG" ? (
                          <span className="replace pidlang">[PG]</span>
                        ) : (
                          <span className="pidlang">PG</span>
                        )}
                      </>
                    )}
                  </Typography>
                </BootstrapTooltip>
              </div>
              <div>
                <button
                  className="navLink replace"
                  onClick={() => {
                    setIsLightMode(!isLightMode); // Update state first
                    const newMode = toggleLightMode(); // Then call toggleLightMode
                  }}
                  handleReplace={handleReplace}
                >
                  {isLightMode ? (
                    lan === "PG" ? (
                      <BootstrapTooltip
                        title={
                          <div>
                            {
                              "Dis design dey inspired by di different different kain light wey I dey use read wen"
                            }
                            <span className="tooltip-alt"> NEPA</span>
                            {" take light."}
                            <span className="tooltip-alt">
                              {"\n \nTorchlight o. \n Candle o. \n Lantern o. \n Moonlight o. \n Firewood o."
                                .split("\n")
                                .map((line, index) => (
                                  <span key={index}>
                                    {line}
                                    <br />
                                  </span>
                                ))}
                            </span>
                          </div>
                        }
                      >
                        <span style={{ fontFamily: "symbols" }}>
                          [Dark Mode]
                        </span>
                      </BootstrapTooltip>
                    ) : (
                      <span style={{ fontFamily: "symbols" }}>[Dark Mode]</span>
                    )
                  ) : lan === "PG" ? (
                    <BootstrapTooltip
                      title={
                        <div>
                          {
                            "Dis design dey inspired by di different different kain light wey I dey use read wen"
                          }
                          <span className="tooltip-alt"> NEPA</span>
                          {" take light."}
                          <span className="tooltip-alt">
                            {"\n \nTorchlight o. \n Candle o. \n Lantern o. \n Moonlight o. \n Firewood o."
                              .split("\n")
                              .map((line, index) => (
                                <span key={index}>
                                  {line}
                                  <br />
                                </span>
                              ))}
                          </span>
                        </div>
                      }
                    >
                      <span style={{ fontFamily: "symbols" }}>
                        [Light Mode]
                      </span>
                    </BootstrapTooltip>
                  ) : (
                    <span style={{ fontFamily: "symbols" }}>[Light Mode]</span>
                  )}
                </button>
              </div>
            </div>{" "}
          </div>
          <Popup
            trigger={<span className="marquee-text replace">?</span>}
            modal
            nested
            onOpen={() => {
              // const gvolume = setvolume();
              if (isVolumeOn) {
                audioFilter.play();
              }
              handleReplace();
            }}
          >
            {(close) => (
              <div className="modal">
                <button
                  className="close"
                  onClick={() => {
                    close();
                    // const gvolume = setvolume();
                    if (isVolumeOn) {
                      audioClose.play();
                    }
                  }}
                >
                  Close
                </button>
                <div className="content">
                  {lan && lan === "PG" ? (
                    <Typography className="index replace">
                      Dis <span className="boldwords">register</span> dey show informate about di different different kain materials wey Bini artists dem use
                      make <span className="boldwords">Bini Bronze</span>. E dey important because wen pesin tok say <span className="replace">'</span>dis tin na <span className="boldwords">Bini Bronze</span><span className="replace">'</span>, pipo go tink say na only bronze material dem use make dem. But, dat one no correct.
                    </Typography>
                  ) : (
                    <Typography className="index replace" style={{fontFamily:"symbols"}}>
                      While the term <span className="boldwords">Benin Bronzes</span> might imply a singular material, this index offers insights into the range of both metallic and non-metallic components that make up the <span className="boldwords">Benin Bronzes</span> documented in this archive. 
                    </Typography>
                  )}
                  <span>
                    <Typography
                      noWrap
                      style={{
                        fontSize: "calc(-0.21156rem + 1.47597vw)",
                        borderBottom: "1px solid",
                        ...fontStyles,
                      }}
                    >
                     <div
    style={{ marginTop: "20px"}}
    className="index-titles"
>
    <div className="bronze-index-title replace">Material</div>
    <div className="index-category-title replace">
        <span className="replace">%</span> Volume
    </div>
    <div className="percentage-change-title replace">
        <span className="replace">%</span> Change
    </div>
</div>

<span className="materialindex replace">
    {volumeD
        .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
        .map((mat, index) => {
            const percentage = (mat.volume).toFixed(1); // One decimal place
            const diff = (mat.volumeChange).toFixed(1); // One decimal place
            const arrowIcon = diff > 0 ? "\u25B2" : diff < 0 ? "\u25BC" : "\u25CC"; // Filled triangle Unicode characters
            const diffClass = diff > 0 ? "increase" : diff < 0 ? "decrease" : "";
            
            return (
                <span className="indexcategory replace" key={mat.name}>
                    <span className="bronzeindexcategory replace">{mat.name.toUpperCase()}</span>
                    <span className={`bronzeindexcount ${diffClass} replace`}>{percentage}%</span>
                    <span className={`percentage-change${percentage !== 0 ? " arrow" : ""} replace`}>{Math.abs(diff)}%</span>
                    <span className={`arrow-display replace ${diffClass}`}>{arrowIcon}</span>

 </span>
            );
        })}
</span>



                    </Typography>

                    {lan && lan === "PG" ? (
                      <Typography className="indexfootnote replace">
                        Di last time wey e update automatically na {lastUpdate}
                      </Typography>
                    ) : (
                      <Typography className="indexfootnote replace">
                        Data as of {lastUpdate} <br />
                        All material classes are based on publicly available
                        data released by museums.{" "}
                      </Typography>
                    )}
                  </span>
                </div>
              </div>
            )}
          </Popup>
          <Button
            onClick={handleToggle}
            className="volume-link"
            style={{
              background: isVolumeOn ? "#00FF00" : "#FF0000",
              color: "#fff",
              transition: "all 0.2s ease-in-out",
              position: "relative",
              marginTop: "8vw",
            }}
          >
            {isVolumeOn ? <VolumeUpIcon /> : <VolumeOffIcon />}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export function setvolume() {
  return gvolume;
}

export default Header;